import React, { Component } from 'react'
import { connect } from 'react-redux'
import Pixels from './pixels'
import Pesquisas from './pesquisas'
import Videos from './videos'

export class Implementacao extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { infoData } = this.props;
		// console.log(infoData)

		return (
			<div>

				{['1', '5'].indexOf(infoData.campaign_types_view_id) > -1 ?
					<Pixels /> : null
				}

				<Pesquisas />

				{['1', '5'].indexOf(infoData.campaign_types_view_id) === -1 ?
					<Videos /> : null
				}
			</div>
		)
	}
}

const mapStateToProps = store => ({
	infoData: store.campaign.current,
	loading_get_campaign: store.campaign.loading_get_campaign
})


const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Implementacao)
