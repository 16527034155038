import api from '../api';
import account from '../pages/Account/FormAccount/account';

export function getSurveys(st, group_id, email, account) {
	// console.log(st)
	// console.log(group_id)
	// console.log(email)
	return api.get('surveys?status=' + st);
}

export function getCreativesStatus(survey_id, start, end) {
	// console.log(survey_id)
	// console.log(start)
	return api.get('surveys/' + survey_id + '/creatives?start=' + start + '&end=' + end);
}

export function getSiteStatus(survey_id, start, end) {
	// console.log("aqui")
	return api.get('surveys/' + survey_id + '/sites?start=' + start + '&end=' + end);
}
