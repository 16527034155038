import axios from 'axios'
var token = localStorage.getItem('bfToken');
const api = axios.create({
	//baseURL: 'http://localhost:8001/'
	// produção 
	baseURL: 'https://ws.brandful.com.br/'
	// staging 
	//baseURL: 'https://staging.brandful.com.br/'
});

if (token) {
	api.defaults.headers.common['Authorization'] = token;
}
export default api
