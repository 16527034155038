import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { getAccountList, deleteAccount } from '../../actions/accountActions'
import { bindActionCreators } from 'redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { translate } from '../../components/Translate';

export class Account extends Component {

	constructor(props) {
		super(props);
		this.user = JSON.parse(localStorage.getItem('user'));
		this._removeContato = this._removeContato.bind(this)
		this._delete = this._delete.bind(this)

		this.state = {
			"accountState": [],

		}


	}


	async componentDidMount() {
		await this.props.getAccountList();
		this.setState({
			"accountState": this.props.conta
		})
		this.setState({ "update": true });
		gtag('event', 'page_view', {
			'page_title': 'Gerenciamento de Contas',
			'page_location': window.location.href
		});

	}

	_delete = async (index, item) => {

		if (window.confirm(translate("Você realmente deseja deletar essa conta?"))) {
			await this.props.deleteAccount(index, item);
			this.setState({ update: true });
		}

	}



	_removeContato(cell, item, formatExtraData, rowIndex) {

		let user = JSON.parse(localStorage.getItem('user'));
		return (user.group_id === "1" ?
			<button className="btn btn-sm btn-danger btn-round" onClick={() => this._delete(rowIndex, item.account_id)}>{translate('Excluir')}</button>
			:
			<button type="button" className="btn btn-danger btn-sm btn-circle" disabled><i className="fa fa-minus"></i></button>
		)
	}

	editButton(cell, item,) {

		let user = JSON.parse(localStorage.getItem('user'));

		return (user.group_id === "1" ?//antes == depois ===
			<Link to={"/conta/edit/" + item.account_id} className="btn btn-sm btn-success btn-round">{translate('Editar')}</Link>
			:
			<button className="btn btn-sm btn-success disabled btn-round" disabled>{translate('Editar')}</button>
		)
	}

	colorButton(conta) {
		return <span style={{ background: conta }} className="square" title=""></span>
	}

	colorTextButton(conta) {
		return <span style={{ background: conta }} className="square" title=""></span>
	}

	colorFeatured(conta) {
		return <span style={{ background: conta }} className="square" title=""></span>
	}
	colorSecondary(conta) {
		return <span style={{ background: conta }} className="square" title=""></span>
	}

	logo(conta) {
		// console.log(conta)        
		return <img height={50} src={conta} />
	}

	handleSizePerPageChange(sizePerPage) {
		localStorage.setItem('perPage', sizePerPage);
	}

	render() {
		let { conta } = this.props;
		// console.log(this.props);
		let perPage = 10;
		if (localStorage.getItem('perPage')) {
			perPage = parseInt(localStorage.getItem('perPage'));
		}

		return (
			<div>
				<Header />

				<div className="alert alert-dark m-0 p-0">
					<div className="container p-0">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active"><a href="#">{translate('Contas')}</a></li>
						</ol>
					</div>
				</div>

				<div className="container">
					<h3 className="pt-4"><strong>{translate('Gerenciamento de Contas')}</strong></h3>
					{
						this.user.group_id === "1" ?

							<Link to="/conta/nova" className="btn btn-success btn-round pull-left" style={{ marginTop: "3px" }}><i className="fa fa-plus"></i> {translate('Nova Conta')}</Link>

							: null
					}
					<div>

						<div className="pt-5">
							{/* <div dangerouslySetInnerHTML={{__html: }}></div>     */}
							{conta === null ?
								<p className="text-center p-4"><span className="fa fa-refresh fa-spin fa-4x"></span></p>
								:

								<BootstrapTable data={conta} hover search options={{ noDataText: "Nenhuma pesquisa até o momento", sizePerPage: perPage, onSizePerPageList: this.handleSizePerPageChange }} searchPlaceholder={translate("Procurar...")} pagination>
									<TableHeaderColumn isKey dataField='account_id' width='50' dataAlign='center' dataSort={true}>ID</TableHeaderColumn>
									<TableHeaderColumn dataField='name' columnClassName="campaignTitle" width='200' dataSort={true} >{translate('Conta')}</TableHeaderColumn>
									<TableHeaderColumn dataField='logo' dataSort={true} dataAlign='center' dataFormat={this.logo}>Logo</TableHeaderColumn>
									<TableHeaderColumn dataField='colorButton' width='100' dataAlign='center' dataSort={true} dataFormat={this.colorButton}>{translate('Cor do Botão')}</TableHeaderColumn>
									<TableHeaderColumn dataField='colorTextButton' width='100' dataAlign='center' dataSort={true} dataFormat={this.colorTextButton}>{translate('Cor de texto do botão')}</TableHeaderColumn>
									<TableHeaderColumn dataField='colorFeatured' width='100' dataAlign='center' dataSort={true} dataFormat={this.colorFeatured}>{translate('Cor de destaque')}</TableHeaderColumn>
									<TableHeaderColumn dataField='colorSecondary' width='100' dataAlign='center' dataSort={true} dataFormat={this.colorSecondary}>{translate('Cor secundária')}</TableHeaderColumn>
									{this.user.group_id === "1" ?
										<TableHeaderColumn dataField='account_id' width='100' dataAlign='center' dataFormat={this.editButton}></TableHeaderColumn>

										: null
									}
									{this.user.group_id === "1" ?
										<TableHeaderColumn dataField='account_id' width='100' dataAlign='center' dataFormat={this._removeContato} delete={this._delete.bind(this)}></TableHeaderColumn>

										: null
									}
								</BootstrapTable>

							}
						</div>


					</div>


				</div>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = store => ({
	conta: store.account.list,
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ getAccountList, deleteAccount }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Account)
