import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getSurveys } from "../../actions/surveysActions";
import { Link } from "react-router-dom";
//import styled from "styled-components";
//import DataTable from "react-data-table-component";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { translate } from "../../components/Translate";

export class Painel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			surveys_active: null,
			surveys_ended: null,
		};
	}

	getSurveysActive = async () => {
		let user = this.props.singleUser;
		let result = await getSurveys("active", user.group_id, user.email, user.account_id);
		let rows = [];
		result.data.map((item) => {
			rows.push({
				id: item.ID,
				campaignID: item.campaignID,
				campaign: item.campaign,
				survey: item.description,
				type_campaign: item.type_campaign,
				tipo: item.campaignType,
				controle: item.controle.total,
				exposto: item.exposto.total,
				total: item.geral.total,
				lift: item.brand_lift,
			});
		});
		this.setState({
			surveys_active: rows,
		});
	};

	getSurveysEnded = async () => {
		let user = this.props.singleUser;
		let result = await getSurveys("ended", user.group_id, user.email, user.account_id);
		let rows = [];
		result.data.map((item) => {
			rows.push({
				id: item.ID,
				campaignID: item.campaignID,
				campaign: item.campaign,
				survey: item.description,
				type_campaign: item.type_campaign,
				tipo: item.campaignType,
				controle: item.controle.total,
				exposto: item.exposto.total,
				total: item.geral.total,
				lift: item.brand_lift,
			});
		});
		this.setState({
			surveys_ended: rows,
		});
	};

	componentDidMount() {
		this.getSurveysActive();
		this.getSurveysEnded();
		gtag('event', 'page_view', {
			'page_title': 'Dashboard',
			'page_location': window.location.href
		});
	}

	goTo(item) {
		window.location.href = "/campanhas/info/" + item.campaignID;
	}

	lift(cell, item) {
		return item.lift ? (
			<strong className="text-success strong">{item.lift}%</strong>
		) : (
			"-"
		);
	}
	handleSizePerPageChange(sizePerPage) {
		localStorage.setItem('perPage', sizePerPage);
	}

	render() {
		let { singleUser } = this.props
		let perPage = 10;
		if (localStorage.getItem('perPage')) {
			perPage = parseInt(localStorage.getItem('perPage'));
		}
		// console.log(this.state)
		return (
			<div>
				<Header />

				<div className="alert alert-dark m-0 p-0">
					<div className="container p-0">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active">
								<a href="#">{translate('Dashboard')}</a>
							</li>
						</ol>
					</div>
				</div>
				<div className="container">
					<h3 className="pl-0 pt-4">
						<strong>{translate('Pesquisas Ativas')}</strong>
					</h3>
					{singleUser.group_id == "1" ?
						<Link to="/campanhas/nova" className="btn btn-success btn-round ml-0 mt-2">
							<i className="fa fa-plus"></i> {translate('Nova Pesquisa')}
						</Link>
						: null
					}

					<div className="pt-5">
						{this.state.surveys_active === null ? (
							<p className="text-center">
								<span className="fa fa-refresh fa-spin"></span>{" "}
							</p>
						) : (
							<div className="panel-body">
								<BootstrapTable
									data={this.state.surveys_active}
									hover
									search
									searchPlaceholder={translate("Procurar...")}
									options={{
										noDataText: translate("Nenhuma pesquisa até o momento"),
										onRowClick: this.goTo,
										sizePerPage: perPage,
										onSizePerPageList: this.handleSizePerPageChange
									}}
									pagination
									tableBodyClass="table-row-click"
								>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										isKey
										dataField="campaignID"
										width="50"
										dataAlign="center"
										className="align-bottom"
										dataSort={true}
									>
										ID
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										dataField="campaign"
										columnClassName="campaignTitle"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Pesquisa')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										dataField="survey"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Pergunta')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										dataField="type_campaign"
										width="100"
										dataAlign="center"
										className="align-bottom h-border"
										dataSort={true}
									>
										{translate('Produto')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										dataField="tipo"
										width="100"
										dataAlign="center"
										className="align-bottom h-border"
										dataSort={true}
									>
										{translate('Tipo')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										colSpan="3"
										className="text-center colSpan"
									>
										{translate('Votos')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="1"
										dataField="controle"
										width="90"
										dataAlign="center"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Controle')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="1"
										dataField="exposto"
										width="90"
										dataAlign="center"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Exposto')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="1"
										dataField="total"
										width="50"
										dataAlign="center"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Total')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										width="70"
										dataAlign="center"
										className="align-bottom l-border"
										dataFormat={this.lift}
										dataSort={true}
									>
										{translate('Lift')}
									</TableHeaderColumn>
								</BootstrapTable>
							</div>
						)}
					</div>
					<div className="pt-5">
						<h3 className="pl-0 pb-4">
							<strong>{translate('Pesquisas Finalizadas')}</strong>
						</h3>
						{this.state.surveys_ended === null ? (
							<p className="text-center">
								<span className="fa fa-refresh fa-spin"></span>{" "}
							</p>
						) : (
							<div className="panel-body">
								<BootstrapTable
									data={this.state.surveys_ended}
									hover
									search
									searchPlaceholder={translate("Procurar...")}
									options={{
										noDataText: translate("Nenhuma pesquisa até o momento"),
										onRowClick: this.goTo,
										sizePerPage: perPage,
										onSizePerPageList: this.handleSizePerPageChange
									}}
									pagination
									tableBodyClass="table-row-click"
								>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										isKey
										dataField="campaignID"
										width="50"
										dataAlign="center"
										className="align-bottom"
										dataSort={true}
									>
										ID
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										dataField="campaign"
										columnClassName="campaignTitle"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Pesquisa')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										dataField="survey"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Pergunta')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										dataField="type_campaign"
										width="100"
										dataAlign="center"
										className="align-bottom h-border"
										dataSort={true}
									>
										{translate('Produto')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										dataField="tipo"
										width="100"
										dataAlign="center"
										className="align-bottom h-border"
										dataSort={true}
									>
										{translate('Tipo')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										colSpan="3"
										className="text-center colSpan"
									>
										{translate('Votos')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="1"
										dataField="controle"
										width="90"
										dataAlign="center"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Controle')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="1"
										dataField="exposto"
										width="90"
										dataAlign="center"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Exposto')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="1"
										dataField="total"
										width="50"
										dataAlign="center"
										className="align-bottom"
										dataSort={true}
									>
										{translate('Total')}
									</TableHeaderColumn>
									<TableHeaderColumn
										row="0"
										rowSpan="2"
										width="70"
										dataAlign="center"
										className="align-bottom l-border"
										dataFormat={this.lift}
										dataSort={true}
									>
										{translate('Lift')}
									</TableHeaderColumn>
								</BootstrapTable>
							</div>
						)}
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	singleUser: JSON.parse(localStorage.getItem('user')),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Painel);
