import React, { Component } from 'react'
import Header from '../../../components/Header'
import Voltar from '../../../components/Voltar'
import Account from './account'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeAccountData, addAccount, getAccount, updateAccount } from '../../../actions/accountActions'
import { translate } from '../../../components/Translate';

export class FormAccount extends Component {

	constructor(props) {
		super(props);
		if (!localStorage.getItem('bfToken')) {
			window.location.href = '/';
		}

	}

	_save = async (e) => {
		e.preventDefault();
		let data = this.props.accountData;

		if (this.props.match) {
			this.props.updateAccount(data, this.props.match.params.id);

		} else {
			await this.props.addAccount(data);
		}

		this.setState({ "update": true })
	}



	async componentDidMount() {
		if (this.props.match) {
			let campaign_id = this.props.match.params.id
			if (campaign_id) {
				await this.props.getAccount(campaign_id);
				// this.setState({"update": true})
				gtag('event', 'page_view', {
					'page_title': 'Editar Conta ' + this.props.accountData.name,
					'page_location': window.location.href
				});
			}
		} else {
			this.setState({ "update": true });
			gtag('event', 'page_view', {
				'page_title': 'Nova Conta',
				'page_location': window.location.href
			});
		}


	}

	render() {
		const { loading } = this.props;
		return (
			<div>
				<Header />

				<div className="alert alert-dark m-0 p-0">
					<div className="container p-0">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="/conta/">{translate('Conta')}</a></li>
						</ol>
					</div>
				</div>

				<div className="container" style={{ paddingBottom: '60px' }}>
					<h2 className="title m-0 h3"> {translate('Editar Conta')}</h2>
					<form enctype='multipart/form-data' onSubmit={this._save}>
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{translate('Conta')}</h4>
							</div>
							<div className="card-body">
								<Account />

							</div>
						</div>
						<div className="row justify-content-center">
							<div className="col-sm-3">
								{loading ?
									<button className="btn btn-success btn-block btn-round disabled" disabled><span className="fa fa-spin fa-refresh"></span></button>
									:
									<button className="btn btn-success btn-block  btn-round">{translate('Salvar Conta')}</button>
								}
							</div>
						</div>

					</form>
				</div>
			</div>
		)
	}
}

const mapStateToProps = store => ({
	accountData: store.account.current,
	changeAccountData: changeAccountData,
	loading: store.campaign.loading_save,
	addAccount: addAccount,
	updateAccount: updateAccount,

})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ changeAccountData, addAccount, getAccount, updateAccount }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FormAccount)
