import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { getCreativesStatus } from '../../../../../actions/surveysActions'
import { getReportCreatives } from '../../../../../actions/aiActions';
import AnimateHeight from 'react-animate-height';
import amostraLegenda from './amostraLegenda'
import { translate } from '../../../../../components/Translate';

function shadeColor(color, percent) {

	var R = parseInt(color.substring(1, 3), 16);
	var G = parseInt(color.substring(3, 5), 16);
	var B = parseInt(color.substring(5, 7), 16);

	R = parseInt(R * (100 + percent) / 100);
	G = parseInt(G * (100 + percent) / 100);
	B = parseInt(B * (100 + percent) / 100);

	R = (R < 255) ? R : 255;
	G = (G < 255) ? G : 255;
	B = (B < 255) ? B : 255;

	R = Math.round(R)
	G = Math.round(G)
	B = Math.round(B)

	var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
	var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
	var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

	return "#" + RR + GG + BB;
}

export class Creatives extends Component {

	opt_chart = {
		tooltips: false,
		maintainAspectRatio: false,
		layout: {
			padding: {
				left: 0,
				right: 0,
				top: 10,
				bottom: 30,
			}
		},
		scales: {
			yAxes: [{
				display: true,
				ticks: {
					callback: function (label) {
						return label + '%'
					},
					beginAtZero: true,
					min: 0,
					fontColor: '#000'
				},

			}],
			xAxes: [{
				display: false,
			}]
		},
		legend: {
			display: true,
			labels: {
				fontColor: '#222',
				fontFamily: 'Roboto',
				fontSize: 11,
				filter: function (item, chart) {
					if (item.text.indexOf('Lift') !== 0) {
						return chart;
					}

				},
			},
			onClick: function () {
				return false;
			}
		},
		animation: {
			duration: 500,
			onProgress: this.animationChart,
			onComplete: this.animationChart
		},
		lift_pts: []
	}

	animationChart() {
		var chartInstance = this.chart,
			ctx = chartInstance.ctx;
		ctx.font = "14px Roboto";
		ctx.fillStyle = "#fff";
		ctx.textAlign = 'center';
		ctx.textBaseline = 'bottom';
		var lift_pts = this.options.lift_pts;
		this.data.datasets.forEach(function (dataset, i) {
			var meta = chartInstance.controller.getDatasetMeta(i);
			meta.data.forEach(function (bar, index) {
				//console.log(dataset.data.length)
				let singleUser = JSON.parse(localStorage.getItem('user'))
				var data = dataset.data[index] + '%';
				if (dataset.data.length > 10) {
					ctx.font = "0px Roboto";
				} else {
					ctx.font = "14px Roboto";
				}
				ctx.fillStyle = "#fff";

				if (bar._model.datasetLabel.indexOf('Lift') !== 0) {
					if (bar._model.y > 280) {
						ctx.fillStyle = "#333";
						ctx.fillText(data, bar._model.x, bar._model.y);
						ctx.fillStyle = "#fff";
					} else {
						ctx.fillText(data, bar._model.x, bar._model.y + 30);
					}
				}
				if (dataset.data.length > 10) {
					ctx.font = "0px Roboto";
				} else {
					ctx.font = "14px Roboto";
				}
				// ctx.fillStyle = "#409444";
				ctx.fillStyle = !singleUser['colorFeatured'] ? '#26d988' : singleUser['colorFeatured'];

				if (bar._model.datasetLabel.indexOf('Lift') === 0) {
					if (lift_pts[index] > 0) {
						ctx.fillText(bar._model.datasetLabel + " " + lift_pts[index] + "%", bar._model.x, bar._model.y);
					} else {
						//console.log(bar)

					}

				} else {

					ctx.font = "14px Roboto";
					ctx.fillStyle = "#333";
					var label = bar._model.label;
					if (label.length > 20 && meta.data.length > 3) {
						label = label.substring(0, 20) + "...";
					}//antes == depois ===
					if (bar._model.datasetLabel === "Criativo") {
						ctx.fillText(label, (bar._model.x - bar._model.width), bar._model.base + 25);
					}
				}
			});
		});
		document.getElementById("chart_creative").src = chartInstance.toBase64Image();


	}

	constructor(props) {
		super(props);
		this.state = {
			img_chart: null,
			creatives_status: [],
			user: JSON.parse(localStorage.getItem('user')),
		}

		getCreativesStatus(props.survey_id, props.start, props.end).then((result) => {
			this.setState({ creatives_status: result.data })
		});
	}


	getLabels = creatives_status => {

		let labels = [];
		if (creatives_status != null) {
			creatives_status.map((label, key) => {
				if (creatives_status.length > 10) {
					labels.push((key + 1));
				} else {
					labels.push(label.creative);
				}

				this.opt_chart['lift_pts'].push(label.lift);
			})
		}
		return labels;
	}

	getDataset = (creatives_status) => {
		let singleUser = this.props.singleUser
		let myData = [];
		let myDataControle = [];
		let dataset = [];
		let myLift = [];
		if (creatives_status != null) {
			creatives_status.map((label) => {
				myData.push(label.percentual);
				myDataControle.push(this.props.controle.percent);

				if (label.percentual > 0 && parseFloat(label.percentual) > this.props.controle.percent) {
					myLift.push(this.props.controle.percent);
				} else {
					myLift.push(0);
				}
			})

			dataset = [{
				label: translate('Controle'),
				data: myDataControle,
				// backgroundColor: '#a0b785',
				backgroundColor: !singleUser['colorSecondary'] ? '#83ecbf' : singleUser['colorSecondary'],
				barPercentage: 1,
			}, {
				label: 'Lift',
				data: myLift,
				barPercentage: 1,
				backgroundColor: 'transparent',
				// borderColor:"#67aa1a",
				borderColor: !singleUser['colorFeatured'] ? '#26d988' : singleUser['colorFeatured'],
				borderWidth: { right: 0, top: 1, left: 0 },
			}, {
				label: translate('Criativo'),
				data: myData,
				barPercentage: 1,
				backgroundColor: !singleUser['colorFeatured'] ? '#26d988' : singleUser['colorFeatured'],
				// backgroundColor: myData.map(function(val, index) {
				//     return '#' + colors[index];
				// }),

			}];
		}
		return dataset;
	}

	amostra = (amostra) => {
		let nivel = [];
		for (let i = 0; i <= 2; i++) {
			nivel.push(<span key={i} className={amostra <= i ? "ml-1 fa fa-circle text-grey" : "ml-1 fa fa-circle text-success"}></span>)
		}
		return nivel
	}

	render() {
		const { controle } = this.props;
		const { creatives_status, user } = this.state;

		const chartData = {
			labels: this.getLabels(creatives_status),
			datasets: this.getDataset(creatives_status)
		};

		return (
			<div>
				<h3 className="pl-4 pt-4 pb-4"><strong>{translate('Criativos')}</strong></h3>
				<div className="container-fluid pb-4">
					<div className="col-md-10 offset-md-1 d-none d-print-block">
						<img src="" id="chart_creative" className="img-responsive" alt='' />
					</div>

					<div className="col-md-10 offset-md-1 d-print-none">
						<Bar data={chartData} options={this.opt_chart} height={350} />
					</div>
				</div>

				{creatives_status !== null ?
					<div>
						<div className="border-vertical">

							<table className="table table-bordered">
								<thead>
									<tr>
										<th className="pl-4" colSpan="2">{translate('Criativo')}</th>
										<th width="150" className="text-center">{translate('Votos Positivos')}</th>
										<th width="150" className="text-center">{translate('Votos Totais')}</th>
										<th width="150" className="text-center">{translate('Results')}</th>
										<th width="150" className="text-center">{translate('Lift Pts')}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="align-middle pl-4" colSpan="2">{translate('Controle')}</td>
										<td className="text-center align-middle">{controle.positivo}</td>
										<td className="text-center align-middle">{controle.total}</td>
										<td className="text-center align-middle">{controle.percent}%</td>
										<td className="text-center align-middle">-</td>
									</tr>
									{creatives_status.map((item, key) => {
										return (<tr key={key}>
											<td className="text-center align-middle">{(key + 1)}</td>
											<td className="align-middle pl-4">{item.creative}
												<span className="align-middle" title={amostraLegenda[item.amostra]}>
													{this.amostra(item.amostra)}
												</span>
											</td>
											<td className="text-center align-middle">{item.positives}</td>
											<td className="text-center align-middle">{item.total}</td>
											<td className="text-center align-middle">{item.percentual}%</td>
											<td className="text-center">{item.lift ?
												<div><span className="text-success h3 strong">{item.lift + '%'}</span></div> :
												'-'}
											</td>
										</tr>)
									})}

								</tbody>
							</table>

						</div>
						<div className='p-3'>
							<h6 className='text-success'><a href="" onClick={async (e) => {
								e.preventDefault();

								this.setState({
									exibir_ai: !this.state.exibir_ai,
									ai_content: "<p class='text-center'><i class='fa fa-spin fa-refresh'></i></p>"
								});
								if (!this.state.exibir_ai) {
									creatives_status.refresh = false;
									let response = await getReportCreatives(creatives_status);
									this.setState({
										ai_content: response.data.text,
										refresh_ai: (user.group_id === '1' ? true : false)
									});
								}
							}
							} className='d-flex align-items-center gap-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lightning-charge" viewBox="0 0 16 16">
									<path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41z" />
								</svg> {translate('AI Analysis - Creatives')} (BETA) <i className={this.state.exibir_ai ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i></a></h6>
							<AnimateHeight duration={1000} height={(this.state.exibir_ai ? 'auto' : 0)} style={{ fontSize: '0.6rem' }}>
								<div dangerouslySetInnerHTML={{ __html: this.state.ai_content }}></div>
								{this.state.refresh_ai ? <button className="btn btn-round btn-success btn-sm hidden-print" onClick={async (e) => {
									e.preventDefault();

									this.setState({
										ai_content: "<p class='text-center'><i class='fa fa-spin fa-refresh'></i></p>",
										refresh_ai: false
									});
									creatives_status[0].refresh = true;
									let response = await getReportCreatives(creatives_status);
									this.setState({
										ai_content: response.data.text,
										refresh_ai: (user.group_id === '1' ? true : false)
									});
								}
								}><i className='fa fa-magic'></i> {translate('Gerar Nova Análise')}</button> : null}
							</AnimateHeight>
						</div>
					</div>
					: <p className="text-center p-4"><span className="fa fa-refresh fa-spin fa-4x"></span></p>
				}
			</div>
		)
	}
}

const mapStateToProps = store => ({
	infoData: store.campaign.current,
	creatives_status: store.campaign.creatives_status,
	loading_get_campaign: store.campaign.loading_get_campaign,
	filter_start: store.campaign.filter_start,
	filter_end: store.campaign.filter_end,
	singleUser: JSON.parse(localStorage.getItem('user'))
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ getCreativesStatus }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Creatives)
