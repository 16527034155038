import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeAccountData, getCampaignTypes } from '../../../actions/accountActions'
import { translate } from '../../../components/Translate';

export class Account extends Component {

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onChangeFile = this.onChangeFile.bind(this);

		this.state = {
			urlTemporaria: [],

		}


	}

	onChange(e) {
		let data = this.props.accountData;
		data[e.target.name] = e.target.value;
		this.props.changeAccountData(data);
		this.setState({ update: true })
	}

	onChangeFile(e) {

		let data = this.props.accountData;
		data[e.target.name] = e.target.files[0];
		this.setState({ update: true })
	}

	// url(){
	//     let url = this.props.accountData.logo;
	//     teste = URL.createObjectURL(accountData.logo); 
	// }
	testando(accountData) {


		this.state.urlTemporaria.push(URL.createObjectURL(accountData));

	}



	render() {
		const { accountData } = this.props;
		// console.log(this.props)

		// if(accountData.logo.name){
		//     this.testando(accountData.logo);

		// }



		// const teste = this.state.urlTemporaria[0]

		// console.log(teste)

		return (
			<fieldset>
				<div className="form-row">
					<div className="form-group col-md-6">
						<label>{translate('Conta')}</label>
						<input type="text" className="form-control text-uppercase" placeholder={translate("Conta")} name="name" onChange={this.onChange} value={accountData['name']} required />
					</div>
					{accountData['logo'] ?
						<div className="form-group col-md-2" >
							<label >Logo </label>

							{accountData.logo.name ?
								<img style={{ display: 'block' }} height={50} src={URL.createObjectURL(accountData.logo)} />
								:
								<img style={{ display: 'block' }} height={50} src={accountData['logo']} />
							}

						</div>
						:
						null
					}


					<div className="">
						{accountData['logo'] ? <label></label>
							: <label>Logo</label>
						}
						{/* <img style={{display: 'block'}} height={50} src={accountData['logo']}/>  */}
						<input style={{ display: 'block' }} type="file" className="1" placeholder="Logo" name="logo" onChange={this.onChangeFile} />

					</div>
				</div>

				<div className="form-row">

					<div className="form-group col-md-3">
						<label>{translate('Cor do Botão')}</label>
						<input type="color" className="form-control" name="colorButton" onChange={this.onChange} value={accountData['colorButton']} required />
					</div>
					<div className="form-group col-md-3">
						<label>{translate('Cor de texto do botão')}</label>
						<input type="color" className="form-control" name="colorTextButton" onChange={this.onChange} value={accountData['colorTextButton']} required />
					</div>
					<div className="form-group col-md-3">
						<label>{translate('Cor destaque')}</label>
						<input type="color" className="form-control" name="colorFeatured" onChange={this.onChange} value={accountData['colorFeatured']} required />
					</div>
					<div className="form-group col-md-3">
						<label>{translate('Cor secundária')}</label>
						<input type="color" className="form-control" name="colorSecondary" onChange={this.onChange} value={accountData['colorSecondary']} required />
					</div>
				</div>
			</fieldset>
		)
	}
}

const mapStateToProps = store => ({
	accountData: store.account.current,

	changeAccountData: changeAccountData,


})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ changeAccountData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Account)
