import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export class Videos extends Component {

	constructor(props) {
		super(props);
		this.user = JSON.parse(localStorage.getItem('user'));
	}
	preview = (event, url) => {
		event.preventDefault();
		window.open(url, 'preview', 'width=400,height=300,status=0,location=0,menubar=0,scrollbars=0,resizable=0,titlebar=0')
	}

	render() {
		const { infoData, singleUser } = this.props;
		// console.log(this.props.infoData.campaign_video)

		return (
			<div>
				<h4><strong>Vídeos</strong></h4>
				<div className="card">
					<table className="table table-bordered">
						<thead>
							<tr>
								<th width="10">URL</th>
								<th>Views</th> <th width="50">Status</th>
							</tr>
						</thead>
						<tbody>

							<tr>
								<td>{infoData.campaign_video_view}</td>
								<td className="text">{infoData.video_views}</td>

								<td className="text-center">{infoData.video_views < 200 ? <span className="fa fa-circle text-success"></span> : <span className="fa fa-circle text-danger"></span>}</td>
							</tr>
						</tbody>

					</table>
				</div>

			</div>
		)
	}
}

const mapStateToProps = store => ({
	infoData: store.campaign.current,
	singleUser: JSON.parse(localStorage.getItem('user'))
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Videos)
