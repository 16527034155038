import api from '../api';

export function playVideo() {
  //api.headers["Authorization"] = localStorage.getItem('panelistToken');
  return api.get("watch/play", {
     
  });
}


export function getVideo(video_hash) {
  return api.get("video/get/"+video_hash );
}




export function viewedVideo(study_video_id=null) {
  return api.post("watch/viewed", {
    study_video_id: study_video_id
  }
  );
}

export function eventVideo(params) {
  return api.post("watchEvents/", params);
}
