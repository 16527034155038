import { combineReducers } from 'redux';
import { user } from './users';
import { campaign } from './campaign';
import { account } from './account';


const rootReducer = combineReducers({
	user: user,
	campaign: campaign,
	account: account
});
export default rootReducer;
