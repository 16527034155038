import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { getCampaigns, deleteCampaign } from '../../actions/campaignActions'
import { bindActionCreators } from 'redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { translate } from '../../components/Translate'
import api from '../../api'

export class Campanhas extends Component {

	constructor(props) {
		super(props);
		this.user = JSON.parse(localStorage.getItem('user'));

	}

	async componentDidMount() {
		await this.props.getCampaigns();
		this.setState({ "update": true });
		gtag('event', 'page_view', {
			'page_title': 'Campanhas',
			'page_location': window.location.href
		});
	}

	delete = async (i, ID) => {
		if (window.confirm("Você realmente deseja deletar essa campanha?")) {
			await this.props.deleteCampaign(i, ID)
			this.setState({ "update": true })
		}

	}
	editButton(cell, item) {
		let current_date = new Date();
		let current_timestamp = Date.parse(current_date.getFullYear() + '-' + (current_date.getMonth() + 1).toString().padStart(2, '0') + '-' + current_date.getDate().toString().padStart(2, '0'));

		//let inicio = Date.parse(item.start.split('/').reverse().join('-'));
		let fim = Date.parse(item.end.split('/').reverse().join('-'));
		let user = JSON.parse(localStorage.getItem('user'));


		return (user.group_id === "1" ?//antes == depois ===
			<Link to={"/campanhas/edit/" + item.ID} className="btn btn-sm btn-success btn-round">{translate('Editar')}</Link>
			:
			<button className="btn btn-sm btn-success disabled btn-round" disabled>{translate('Editar')}</button>
		)
	}
	infoPesquisa(cell, item) {
		return <Link to={'/campanhas/info/' + item.ID}>{cell}</Link>
	}
	status(cell, item) {
		let current_date = new Date();
		let current_timestamp = Date.parse(current_date.getFullYear() + '-' + (current_date.getMonth() + 1).toString().padStart(2, '0') + '-' + current_date.getDate().toString().padStart(2, '0'));
		let fim = Date.parse(item.end.split('/').reverse().join('-'));


		if (current_timestamp > fim) {
			return <span className="fa text-danger fa-circle" title={translate("Pesquisa Finalizada")}></span>
		} else {
			if (item.respostas != "0") {
				return <span className="fa text-success fa-circle" title={translate("Pixels Ativados e Contabilizando Respostas")}></span>
			}
			if (item.pixels_views != "0") {
				return <span className="fa text-warning fa-circle" title={translate("Pixels Ativados e Aguardando Respostas")}></span>
			}
			return <span className="fa text-default fa-circle" title={translate("Aguardando Pixels e Respostas")}></span>
		}
	}
	handleSizePerPageChange(sizePerPage) {
		localStorage.setItem('perPage', sizePerPage);
	}

	async dumpSurveys() {
		if (this.user.group_id === "1") {
			api.get('campaign/dumpCsv', {
				responseType: 'blob',
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'pesquisas.csv');
				document.body.appendChild(link);
				link.click();
			})
		}
	}
	render() {
		const { campaigns, singleUser } = this.props;
		let perPage = 10;
		if (localStorage.getItem('perPage')) {
			perPage = parseInt(localStorage.getItem('perPage'));
		}


		return (
			<div>
				<Header />
				<div className="alert alert-dark m-0 p-0">
					<div className="container p-0">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active"><a href="#">{translate('Pesquisas')}</a></li>
						</ol>
					</div>
				</div>

				<div className="container">
					<h3 className="pt-4"><strong>{translate('Gerenciamento de Pesquisas')}</strong></h3>
					{
						this.user.group_id === "1" ?
							<div>
								<Link to="/campanhas/nova" className="btn btn-success btn-round pull-left" style={{ marginTop: "3px" }}><i className="fa fa-plus"></i> {translate('Nova Pesquisa')}</Link>
								<button className="btn btn-default btn-round pull-left" style={{ marginTop: "3px" }} onClick={() => this.dumpSurveys()}><i className="fa fa-download"></i> {translate('Exportar Pesquisas')}</button>
							</div>
							: null
					}

					<div className="pt-5">
						{campaigns === null ?
							<p className="text-center p-4"><span className="fa fa-refresh fa-spin fa-4x"></span></p>
							:

							<BootstrapTable data={campaigns} hover search options={{ noDataText: translate("Nenhuma pesquisa até o momento"), sizePerPage: perPage, onSizePerPageList: this.handleSizePerPageChange }} searchPlaceholder={translate("Procurar...")} pagination >
								<TableHeaderColumn isKey dataField='ID' width='50' dataAlign='center' dataSort={true}>ID</TableHeaderColumn>
								<TableHeaderColumn dataField='name' className={'teste'} columnClassName="campaignTitle" style={{ color: "red" }} dataSort={true} dataFormat={this.infoPesquisa}>{translate('Pesquisa')}</TableHeaderColumn>
								<TableHeaderColumn dataField='advertiser' dataSort={true} columnClassName="text-uppercase">{translate('Anunciante')}</TableHeaderColumn>
								<TableHeaderColumn dataField='type_campaign' width='100' dataAlign='center' dataSort={true}>{translate('Produto')}</TableHeaderColumn>
								<TableHeaderColumn dataField='campaign_type' width='100' dataAlign='center' dataSort={true}>{translate('Tipo')}</TableHeaderColumn>
								<TableHeaderColumn dataField='status' width='90' dataAlign='center' dataFormat={this.status}>{translate('Status')}</TableHeaderColumn>
								<TableHeaderColumn dataField='start' width='120' dataAlign='center' dataSort={true}>{translate('Início')}</TableHeaderColumn>
								<TableHeaderColumn dataField='end' width='120' dataAlign='center' dataSort={true}>{translate('Término')}</TableHeaderColumn>
								<TableHeaderColumn dataField='surveys_qty' width='100' dataAlign='center' dataSort={true}>{translate('Perguntas')}</TableHeaderColumn>
								{this.user.group_id == "1" ?
									<TableHeaderColumn dataField='ID' width='100' dataAlign='center' dataFormat={this.editButton}></TableHeaderColumn>
									: null
								}
							</BootstrapTable>

						}
					</div>

				</div>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = store => ({
	campaigns: store.campaign.list,
	// singleUser: JSON.parse(localStorage.getItem('user')),
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ getCampaigns, deleteCampaign }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Campanhas)
