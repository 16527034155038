import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Login from './pages/Login'
import Painel from './pages/Painel'
import Campanhas from './pages/Campanhas'

import FormCampanha from './pages/Campanhas/FormCampanha'
import InfoCampanha from './pages/Campanhas/InfoCampanha'
import Administradores from './pages/Administradores'
import FormAdministradores from './pages/Administradores/FormAdministradores'
import { getInfo, logout } from './actions/userActions'

import Video from './pages/Video'
import Account from './pages/Account'
import FormAccount from './pages/Account/FormAccount'


class App extends Component {
	
	constructor(props){
		super(props);
		if(localStorage.getItem('bfToken')){
			this.props.getInfo();
		}
	}
	
	render(){  
        let home = '/painel/';
        let user = {
            group_id: 0
        };
        if(localStorage.getItem('bfToken')){
            user = JSON.parse(localStorage.getItem('user'));
            if(user.group_id !== "1"){//antes != depois !==
                home = '/painel/';
            }
        }
		return (
			<div>
				<Router>
					<Route path="/" exact strict render={() => (
						!localStorage.getItem('bfToken') ? <Login /> : <Redirect to={home} />
					)} />
					<Route path="/painel/" exact strict render={() => (
                            localStorage.getItem('bfToken') ? ( <Painel />) : (<Redirect to="/" />)
                    )} />
					<Route path="/campanhas/" exact strict render={() => (
						localStorage.getItem('bfToken') ? (<Campanhas />) : (<Redirect to="/" />)
					)} />
					<Route path="/logout/" exact strict render={() => (
						logout()
					)} />

					<Route path="/video/:id" exact strict  component={ Video } 
					/>
					
					<Route path="/campanhas/info/:id" exact strict component={ InfoCampanha } />
					
					{/* <Route path="/conta/info/:id" exact strict component={ InfoAccount } /> */}
                    {user.group_id == "1" ?
                    <div>
                        {/* <Route path="/painel/" exact strict render={() => (
                            localStorage.getItem('bfToken') ? ( <Painel />) : (<Redirect to="/" />)
                        )} /> */}

						<Route path="/conta/" exact strict render={() => (
                            localStorage.getItem('bfToken') ? ( <Account />) : (<Redirect to="/" />)
                        )} />

						<Route path="/conta/nova" strict render={() => (
                            localStorage.getItem('bfToken') ? (user.group_id == "1" ? <FormAccount /> : <Redirect to="/conta/" />) : (<Redirect to="/" />)
                        )} />    
					    <Route path="/conta/edit/:id" exact strict component={ FormAccount } />


                        <Route path="/campanhas/nova" strict render={() => (
                            localStorage.getItem('bfToken') ? (user.group_id == "1" ? <FormCampanha /> : <Redirect to="/campanhas/" />) : (<Redirect to="/" />)
                        )} />
						    
					    <Route path="/campanhas/edit/:id" exact strict component={ FormCampanha } />
                        <Route path="/administradores/" exact strict render={() => (
						    localStorage.getItem('bfToken') ? (<Administradores />) : (<Redirect to="/" />)
					    )} />
					    <Route path="/administradores/edit/:id" exact strict component={ FormAdministradores } />
					    <Route path="/administradores/novo" exact strict component={ FormAdministradores } />
                    </div>
                    : null}
					
				</Router>
			</div>
		);
	}
}
			
const mapStateToProps = store => ({
	isLogged: store.user.isLogged,
})


const mapDispatchToProps = dispatch => 
    bindActionCreators({ getInfo }, dispatch);
			
export default connect(mapStateToProps, mapDispatchToProps)(App);
