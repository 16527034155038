import React, { Component } from 'react'
import './style.css';
import { connect } from 'react-redux'
import { login as loginAct, recuperarSenha } from '../../actions/userActions';
import { bindActionCreators } from 'redux';
import { translate } from '../../components/Translate';

export class Login extends Component {


	constructor(props) {
		super(props);
		this.state = {
			showLembrete: false
		}
		this.current_bg = 0;
		this.imgBG = [];
		this.handleLogin = this.handleLogin.bind(this);
		this.onChange = this.onChange.bind(this);
		this.openModalLembrete = this.openModalLembrete.bind(this);
		this.recuperarSenha = this.recuperarSenha.bind(this);

		let lang = window.navigator.language.toLowerCase();
		if (lang.indexOf('es') > -1) {
			lang = 'es';
		} else if (lang.indexOf('en') > -1) {
			lang = 'en-us';
		} else {
			lang = 'pt-br';
		}
		this.state.lang = lang;

		gtag('event', 'page_view', {
			'page_title': 'Login',
			'page_location': window.location.href
		});
	}

	componentWillMount() {
		document.body.classList.add('login-page');
	}

	componentWillUnmount() {
		document.body.classList.remove('login-page');
		clearInterval(this.imgBGInterval);
	}

	recuperarSenha(e) {
		e.preventDefault();

		var post = { "email": this.state.loginLembrete, lang: this.state.lang }
		this.props.recuperarSenha(post);
	}


	handleLogin(e) {
		e.preventDefault();
		this.props.loginAct(this.state);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	openModalLembrete(e) {
		e.preventDefault();
		this.setState({ showLembrete: true })
	}
	closeModal() {
		this.setState({ showLembrete: false })
	}
	shuffle(array) {
		array.sort(() => Math.random() - 0.5);
	}


	render() {
		return (
			<div className="page-header header-filter">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6 ml-auto mr-auto">
							<div className="card card-login" style={{ border: 0 }}>
								<div className="p-2">
									<img src="/assets/img/logo.png" alt="Brandful" className="img-responsive" />
								</div>
								<form className="form" onSubmit={this.handleLogin} style={{ minHeight: "210px" }}>

									<div className="card-body" style={{ paddingTop: '20px' }}>
										{this.props.response_login ?
											<div className="alert alert-danger text-center">{translate(this.props.response_login.msg)}</div>
											: ''
										}
										<div className="form-group">
											<label>{translate('Email')}</label>
											<input type="email" className="form-control" placeholder={translate('Email')} required name="email" onChange={this.onChange} />
										</div>
										<div className="form-group">
											<label>{translate('Senha')}</label>
											<input type="password" className="form-control" placeholder={translate("Senha")} name="password" required onChange={this.onChange} />
										</div>
										<a href="/" onClick={this.openModalLembrete}>{translate("Esqueci minha senha")}</a>
										<div className="pull-right btn-submit-login">
											{this.props.loading_login ?
												<p><span className="fa fa-2x fa-refresh fa-spin"></span></p>
												:
												<button className="btn btn-success btn-sm btn-round">{translate("Entrar")}</button>
											}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				{
					this.state.showLembrete ?
						<div>
							<div className="modal fade show" style={{ display: "block" }}>
								<div className="modal-dialog">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">{translate("Recuperar Senha")}</h5>
											<button type="button" className="close" onClick={() => this.closeModal()}>
												<i className="material-icons">clear</i>
											</button>
										</div>
										<div className="modal-body">
											{
												this.props.lembrete_response ?
													<div className={this.props.lembrete_response.status === "sucesso" ? "alert alert-success" : "alert alert-danger"}>{translate(this.props.lembrete_response.msg)}</div>
													: null
											}
											<form onSubmit={this.recuperarSenha}>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="material-icons">account_circle</i>
														</span>
													</div>
													<input type="email" className="form-control" placeholder={translate("Email")} required name="loginLembrete" onChange={this.onChange} />
												</div>
												{this.props.lembrete_loading ?
													<p className="text-center"><span className="fa fa-2x fa-refresh fa-spin"></span></p>
													:
													<button className="btn btn-link btn-success btn-block">{translate('Recuperar Senha')}</button>
												}
											</form>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-backdrop fade show" onClick={() => this.closeModal()}></div>
						</div>
						: null
				}
			</div>
		)
	}
}

const mapStateToProps = store => ({
	loginAct: loginAct,
	recuperarSenha: recuperarSenha,
	loading_login: store.user.loading_login,
	isLogged: store.user.isLogged,
	response_login: store.user.response_login,
	lembrete_loading: store.user.lembrete_loading,
	lembrete_response: store.user.lembrete_response
})


const mapDispatchToProps = dispatch =>
	bindActionCreators({ loginAct, recuperarSenha }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login)
