
import account_model from '../models/account'
const initialState = {
    list: null,
    loading_save: false,
    loading_get_account: true,
    account_types: [],
    survey_types: [],
    industries:[],
    current: account_model,
    account_status: null,
    surveys_status: null,
    creatives_status: null,
    sites_status: null,
    filter_start: null,
    filter_end: null
}

export function account(state = initialState, action) {
    let list = state.list;
    switch (action.type) {
        case "RESET_CURRENT":
            return Object.assign({}, state, {current: account_model});
        case "LOADING_SAVE":
            return Object.assign({}, state, {loading_save: action.loading_save});
        case "ACCOUNT_CURRENT_DATA":
            return Object.assign({}, state, {current: action.accountData});
        case "GET_ALL_ACCOUNT":
            return Object.assign({}, state, {list: action.data});
        case "GET_ACCOUNT":
            return Object.assign({}, state, {current: action.data, loading_get_account: false});
        case "DELETE_ACCOUNT":
            const obj = list.findIndex(x=>x.account_id === action.index)                      
            list.splice(obj, 1)
            return Object.assign({}, state, {list: list});
        case "ADD_ACCOUNT":
            list.push(action.data);
            return Object.assign({}, state, {list: list});
        case "GET_ACCOUNT_TYPES":
            return Object.assign({}, state, {account_types: action.data});
        case "GET_INDUSTRIES":
            return Object.assign({}, state, {industries: action.data});
        case "GET_SURVEY_TYPES":
            return Object.assign({}, state, {survey_types: action.data});
        case "LOADING_GET_ACCOUNT":
            return Object.assign({}, state, {current: account_model});
        case "STATUS_ACCOUNT":
            return Object.assign({}, state, {account_status: action.data});
        case "LOADING_STATUS_ACCOUNT":
            return Object.assign({}, state, {getSurveysStatus: true});
        case "STATUS_SURVEYS":
            return Object.assign({}, state, {surveys_status: action.data, getSurveysStatus: true});
        case "STATUS_CREATIVES":
            return Object.assign({}, state, {creatives_status: action.data});
        case "STATUS_SITES":
            return Object.assign({}, state, {sites_status: action.data});
        case "UPDATE_SURVEYS_CREATIVES":
            return Object.assign({}, state, {creatives_status: null, surveys_status: null, filter_start: action.data.start, filter_end: action.data.end});
        case "ADD_USER":
            return state.push(action.data);
        default:
            return state;
    }
}
