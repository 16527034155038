import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from '../../../components/Translate';

export class Pixels extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		// console.log(this.props.infoData)

		const { infoData } = this.props;
		return (
			<div>
				<h4><strong>Pixels</strong></h4>
				<div className="card">
					<table className="table table-bordered">
						<thead>
							<tr>
								<th width="100">ID</th>
								<th width="200">{translate('Criativo')}</th>
								<th width="200" className="text-center">{translate('Site')}</th>
								<th>{translate('Pixel')}</th>
								<th className="text-center" width="50">{translate('Status')}</th>
								<th className="text-center" width="150">{translate('Último Disparo')}</th>
								<th className="text-center" width="150">{translate('Disparos de Pixels')}</th>
							</tr>
						</thead>
						<tbody>
							{
								infoData.pixels.map((creative, i) => {
									return (
										<tr key={i}>
											<td>{creative.ID}</td>
											<td className="text-success-dark">{creative.creative}</td>
											<td>{creative.site}</td>
											<td><input type="text" defaultValue={creative.pixel} readOnly className="inputRead" /></td>
											<td className="text-center">{creative.status ? <span className="fa fa-circle text-success"></span> : <span className="fa fa-circle text-danger"></span>}</td>
											<td className="text-center">{creative.last_view}</td>
											<td className="text-center">{creative.views}</td>
										</tr>
									)
								})
							}
						</tbody>

					</table>
				</div>
			</div>
		)
	}
}

const mapStateToProps = store => ({
	infoData: store.campaign.current,
})


const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Pixels)
