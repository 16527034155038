import api from '../api';

export function reformatDate(dateStr) {
	let dateArray = dateStr.split("-")
	return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]
}
export function save(data) {

	return dispatch => {
		dispatch({
			type: 'LOADING_LOGIN',
		});

		data.token = localStorage.getItem('bfToken');
		return api.post('usuario/editar?json=1', data).then(res => {
			dispatch({
				type: "SAVE_COMPLETE",
				"response": res.data
			})
		});
	}
}

export function setCurrentUser(user) {
	return {
		type: "SET_CURRENT_USER",
		user
	};
}
export function loginError(response) {
	return {
		type: "LOGIN_ERROR",
		response
	}
}
export function logout() {
	localStorage.clear();
	window.location.href = '/';
}

export function changeUserData(data) {
	return dispatch => {
		dispatch({
			type: "CHANGE_USER_DATA",
			edit_user: data
		});
	}
}

export function login(data) {
	let post_data = {
		email: data.email,
		password: data.password
	}

	return dispatch => {
		dispatch({
			type: 'LOADING_LOGIN',
		});
		return api.post('login', data).then(res => {
			if (res.data.status === 1) {
				if (res.data.change_pass) {
					window.location.href = res.data.change_pass;
					return;
				}
				const token = res.data.token;
				api.defaults.headers.common['Authorization'] = token;
				localStorage.setItem('bfToken', token);
				localStorage.setItem('user', JSON.stringify(res.data.user));
				dispatch({
					type: "USER_DATA",
					response: res.data.user
				});
			} else {
				dispatch(loginError(res.data));
			}
			return res
		});
	}
}

export function getInfo() {
	return dispatch => {
		return api.get('user').then(response => {
			localStorage.setItem('user', JSON.stringify(response.data));
			dispatch({
				type: "USER_DATA",
				response: response.data
			});
		});
	}
}

export function recuperarSenha(post_data) {
	return dispatch => {
		dispatch({
			type: "LOADING_LEMBRETE",
		});
		return api.post('lembrarSenha/', post_data).then(response => {
			dispatch({
				type: "RESPONSE_LEMBRETE",
				response: response.data
			});
		});
	}
}



export function getUsers() {
	return dispatch => {
		dispatch({
			type: "LOADING_LIST_USERS",
		});
		return api.get('users').then(response => {
			dispatch({
				type: "RESPONSE_USERS",
				response: response.data
			});
		});
	}
}

export function getUser(user_id) {
	return dispatch => {
		return api.get('users/' + user_id).then(response => {
			dispatch({
				type: "GET_USER_EDIT",
				response: response.data
			});
		});
	}
}

export function getUserTypesGroups() {
	return dispatch => {
		return api.get('groups/').then(response => {
			dispatch({
				type: "RESPONSE_USER_TYPES",
				response: response.data
			});
		});
	}
}
export function getUserTypesAccount() {

	return dispatch => {
		return api.get('account/').then(response => {
			dispatch({
				type: "RESPONSE_USER_TYPES_ACCOUNT",
				response: response.data
			});

		});

	}
}

export function deleteUser(index, user_id) {
	return dispatch => {
		dispatch({
			type: "RESPONSE_DELETE_USER",
			index: index
		});
		return api.delete('users/' + user_id);
	}
}

export function saveUser(data, user_id) {
	return dispatch => {
		dispatch({
			type: "LOADING_SAVE_USER",
			loading_save: true
		});
		if (user_id) {
			api.patch("users/" + user_id, data).then(response => {
				alert(response.data.msg);
				dispatch({
					type: "LOADING_SAVE_USER",
					loading_save: false
				});
			});
		} else {
			api.post("users", data).then(response => {
				alert(response.data.msg);
				if (response.data.status === 1) {
					window.location.href = '/administradores/';
				}
				dispatch({
					type: "LOADING_SAVE_USER",
					loading_save: false
				});
			});

		}
	}
}

export function getUsersAtivos() {
	return api.get('users?status=1');
}

export function getCampaignsId(userId) {
	return api.get('Users/CampaignsId/' + userId)
}

export function deleteCampaignsId(campaign_contact_id) {
	return api.get('Users/deleteCampaignsId/' + campaign_contact_id)
}

export function addCampaigns(user_id, campaign_id) {

	return api.get('Users/addCampaigns/' + user_id + "/" + campaign_id)
}

export function listCampaigns(email) {
	// console.log("Caiu aqui no js")

	return api.post('users/campaigns/', { email: email })


}



