import api from '../api';

export function changeAccountData(data) {
    return async dispatch => { 
        dispatch({
            type: "ACCOUNT_CURRENT_DATA",
            accountData: data
        });
    }
}

export function addAccount(data) {
    let config =  { headers: {"Content-Type": "multipart/form-data",} };
    let formData = new FormData();
    Object.keys(data).map(function(val, key) {
        formData.append(val, data[val]);
    })
    return async dispatch => { 
        dispatch({
            type: "LOADING_SAVE",
            loading_save: true
        });
        await api.post("account", formData, config).then(response => {
           
            if(response.data.status === 1){
                dispatch({
                    type: "ADD_ACCOUNT",
                    data: {
                        "ID" : response.data.account_id,                        
                        "respostas": "0",
                        "name": data.name
                    }
                });
                alert(response.data.msg);
                // window.location.href = '/account/';
                window.location.href = '/conta/edit/'+response.data.account_id;
            } else {
                alert(response.data.msg);
            }
            dispatch({
                type: "LOADING_SAVE",
                loading_save: false
            });
        });
    }
}

export function updateAccount(data, account_id) {    
    let config =  { headers: {"Content-Type": "multipart/form-data",} };
    let formData = new FormData();
    Object.keys(data).map(function(val, key) {
        formData.append(val, data[val]);
    })
       
    return async dispatch => { 
        dispatch({
            type: "LOADING_SAVE",
            loading_save: true
        });
        await api.post("account/update/"+account_id, formData, config).then(response => {
          
            alert(response.data.msg);
            dispatch({
                type: "LOADING_SAVE",
                loading_save: false
            });
        });
        
    }
   
}

export function getAccountList(){
        
    return async dispatch => {
        dispatch({
            type: 'LOADING_GET_ACCOUNT',
        });
        return await api.get('account').then(res => {
            dispatch({
                "type": "GET_ALL_ACCOUNT",
                "data": res.data
            })
        });
    }
}
export function getAccount(account_id){
   
    return async dispatch => {
        dispatch({
            type: 'LOADING_GET_ACCOUNT',
        });
        return await api.get('account/accountId/'+account_id).then(res => {
            dispatch({
                "type": "GET_ACCOUNT",
                "data": res.data
            })
        });
    }
}

export function deleteAccount(index, account_id){   
    // return async dispatch => {
    //     dispatch({
    //         "type": 'DELETE_ACCOUNT',
    //         "index": index
    //     });
    //     return api.delete('account/deleteAccountId/'+account_id);
    // }   
    
    return async dispatch => { 
        dispatch({
            type: "LOADING_SAVE",
            loading_save: true
        });
       
        await api.delete('account/deleteAccount/'+account_id).then(response => {
            
            if(response.data.status === 1){                
                dispatch({
                    "type": 'DELETE_ACCOUNT',
                    "index": account_id
                    
                });    
            // window.location.href = '/account/'
            } 
            alert(response.data.msg);
            dispatch({
                type: "LOADING_SAVE",
                loading_save: false
            });
           
        });
    }
   
        
    





}

