import React, { Component } from "react";

import Play from "./play.js";
import Login from "./login.js";

export default class Playlist extends Component {


	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			videos: null,
			isLogged: false,
		};
		this.onLogin = this.onLogin.bind(this);
	}

	onLogin() {
		this.setState({ isLogged: true });
	}

	render() {
		const { isLogged } = this.state;
		return (isLogged ? <Play campaign_id={this.props.match.params.id} /> : <Login onLogin={this.onLogin} campaign_id={this.props.match.params.id} />);
	}
}

