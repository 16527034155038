import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { translate } from '../../../components/Translate';

export class Pesquisas extends Component {

	constructor(props) {
		super(props);
		this.user = JSON.parse(localStorage.getItem('user'));
	}
	preview = (event, url) => {
		event.preventDefault();
		let preview = new URL(url);
		preview.searchParams.append('preview', 'true');
		window.open(preview.href, 'preview', 'width=400,height=300,status=0,location=0,menubar=0,scrollbars=0,resizable=0,titlebar=0')
	}

	render() {
		const { infoData, singleUser } = this.props;

		// console.log(this.props);
		return (
			<div>
				<h4><strong>{translate('Formulários')}</strong></h4>
				<div className="card">
					<table className="table table-bordered">
						<thead>
							<tr>
								<th width="10">ID</th>
								<th>{translate('Pergunta')}</th>
								<th width="50">{translate('Preview')}</th>
								<th>{translate('Tag')}</th>
								<th width="50">{translate('Status')}</th>
							</tr>
						</thead>
						<tbody>
							{
								Object.keys(infoData.surveys).map(survey_id => {
									return (
										<tr key={survey_id}>
											<td>{infoData.surveys[survey_id].campaign_survey_id}</td>
											<td className="text-success-dark">{infoData.surveys[survey_id].description}</td>
											<td className="text-center">
												{/* <a href={infoData.surveys[survey_id].url} onClick={(e) => this.preview(e, infoData.surveys[survey_id].url)}><img src="/assets/img/eye.png" alt="Visualizar" height="22" />kkkkk</a> */}
												<a href={infoData.surveys[survey_id].url} onClick={(e) => this.preview(e, infoData.surveys[survey_id].url)}>
													<svg xmlns="http://www.w3.org/2000/svg" backgroundColor='blue' width="30" height="19" fill={!singleUser['colorFeatured'] ? '#26d988' : singleUser['colorFeatured']} class="bi bi-eye x" viewBox="0 -1 16 15">
														<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
														<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
													</svg>
												</a>
											</td>
											<td><input type="text" defaultValue={infoData.surveys[survey_id].iframe} readOnly className="inputRead" /></td>
											<td className="text-center">{infoData.surveys[survey_id].answers === 0 ? <span className="fa fa-circle text-danger"></span> : <span className="fa fa-circle text-success"></span>}</td>
										</tr>
									)
								})
							}
							{infoData.surveys.length > 0 ?
								<tr>
									<td></td>
									<td className="text-success-dark">{translate('Combo Perguntas')}</td>
									<td className="text-center">
										<a href={infoData.surveys_url} onClick={(e) => this.preview(e, infoData.surveys_url)}>
											<svg xmlns="http://www.w3.org/2000/svg" backgroundColor='blue' width="30" height="19" fill={!singleUser['colorFeatured'] ? '#26d988' : singleUser['colorFeatured']} class="bi bi-eye x" viewBox="0 -1 16 15">
												<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
												<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
											</svg>
										</a>
									</td>
									<td><input type="text" defaultValue={infoData.surveys_iframe} readOnly className="inputRead" /></td>
									<td className="text-center"><span className="fa fa-circle text-success"></span></td>
								</tr>
								: null}
						</tbody>

					</table>
				</div>
			</div>
		)
	}
}

const mapStateToProps = store => ({
	infoData: store.campaign.current,
	singleUser: JSON.parse(localStorage.getItem('user'))
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Pesquisas)
