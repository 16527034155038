import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeCampaignData } from '../../../actions/campaignActions'
import { translate } from '../../../components/Translate'

export class Creatives extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(e, index) {
		let data = this.props.campaignData
		data.creatives[index][e.target.name] = e.target.value;

		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	_novoItem = () => {
		let data = this.props.campaignData
		data.creatives.push({ creative: '', 'views': 0, "campaign_creative_id": null })
		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	_removeItem = index => {
		let data = this.props.campaignData
		data.creatives.splice(index, 1);
		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	render() {
		const { campaignData } = this.props;

		return (
			<fieldset className="mt-2">
				<h4 className="card-title">{translate('Criativos')}</h4>

				{campaignData.creatives.map((item, i) => {
					return (
						<div className="form-row" key={i}>
							<div className="form-group col-md-11">
								<label>{translate('Nome do Criativo')}</label>
								<input type="text" className="form-control" name="creative" onChange={(val) => { this.onChange(val, i) }} value={item.creative} required />
							</div>

							<div className="form-group col-md-1 align-bottom d-flex mb-2 align-items-end">
								{campaignData.creatives.length > 1 ?
									<button type="button" className="btn btn-danger btn-sm btn-circle" onClick={() => this._removeItem(i)}><i className="fa fa-minus"></i></button>
									: null
								}
							</div>
						</div>
					)
				})}

				<button type="button" className="btn btn-success btn-round" onClick={this._novoItem}><i className="fa fa-plus"></i> {translate('Adicionar')}</button>

			</fieldset>
		)
	}
}

const mapStateToProps = store => ({
	campaignData: store.campaign.current,
	changeCampaignData: changeCampaignData
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ changeCampaignData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Creatives)
