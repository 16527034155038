import api from '../api';

export async function getReport(data) {
	return api.post('ai', data);
}
export async function getReportAnswers(data) {
	return api.post('ai/answers', data);
}

export async function getReportCreatives(data) {
	return api.post('ai/creatives', data);
}
export async function getReportSites(data) {
	return api.post('ai/sites', data);
}
