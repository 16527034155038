import api from '../api';

export function changeCampaignData(data) {
	return dispatch => {
		dispatch({
			type: "CAMPAIGN_CURRENT_DATA",
			campaignData: data
		});
	}
}

export function addCampaign(data) {

	return dispatch => {
		dispatch({
			type: "LOADING_SAVE",
			loading_save: true
		});
		api.post("campaign", data).then(response => {
			// console.log(response.data);
			if (response.data.status === 1) {
				dispatch({
					type: "ADD_CAMPAIGN",
					data: {
						"ID": response.data.campaign_id,
						"end": data.end.split('-').reverse().join('/'),
						"start": data.start.split('-').reverse().join('/'),
						"respostas": "0",
						"name": data.name
					}
				});
				alert(response.data.msg);
				window.location.href = '/campanhas/info/' + response.data.campaign_id;
			} else {
				alert(response.data.msg);
			}
			dispatch({
				type: "LOADING_SAVE",
				loading_save: false
			});
		});
	}
}

export function updateCampaign(data, campaign_id) {
	// console.log(data);

	return dispatch => {
		dispatch({
			type: "LOADING_SAVE",
			loading_save: true
		});
		api.patch("campaign/" + campaign_id, data).then(response => {
			alert(response.data.msg);
			dispatch({
				type: "LOADING_SAVE",
				loading_save: false
			});
		});
	}
}

// export function updateCampaign(data, campaign_id) {    
//     let config =  { headers: {"Content-Type": "multipart/form-data",} };
//     let formData = new FormData();
//     console.log(data)

//     Object.keys(data).map(function(val, key) {

//         formData.append(val, data[val]);
//     })       

//     console.log(formData)
//     return  dispatch => { 
//         dispatch({
//             type: "LOADING_SAVE",
//             loading_save: true
//         });
//          api.post("campaign/update/"+campaign_id, formData, config).then(response => {

//             alert(response.data.msg);
//             dispatch({
//                 type: "LOADING_SAVE",
//                 loading_save: false
//             });
//         });

//     }

// }

export function uploadVideo(data) {
	let config = { headers: { "Content-Type": "multipart/form-data", } };
	let formData = new FormData();
	Object.keys(data).map(function (val, key) {

		formData.append(val, data[val]);
	})
	return api.post('video', formData, config);
}



export function getCampaigns() {
	return async dispatch => {
		dispatch({
			type: 'LOADING_GET_CAMPAIGNS',
		});

		return api.get('campaign').then(res => {
			dispatch({
				"type": "GET_ALL_CAMPAIGNS",
				"data": res.data
			})
		});
	}
}

export function getCampaignTypesView() {
	return async dispatch => {
		return api.get('campaignTypesView').then(res => {
			dispatch({
				"type": "GET_CAMPAIGN_TYPES_VIEW",
				"data": res.data
			})
		});
	}
}

export function getCampaignTypes() {
	return async dispatch => {
		return api.get('campaignTypes').then(res => {
			dispatch({
				"type": "GET_CAMPAIGN_TYPES",
				"data": res.data
			})
		});
	}
}

export function getIndistries() {
	return async dispatch => {
		return api.get('industries').then(res => {
			dispatch({
				"type": "GET_INDUSTRIES",
				"data": res.data
			})
		});
	}
}

export function resetCampaignData() {
	return async dispatch => {
		dispatch({
			"type": "RESET_CURRENT"
		})
	}
}

export function getSurveyTypes() {
	return async dispatch => {
		return api.get('surveyTypes').then(res => {
			dispatch({
				"type": "GET_SURVEY_TYPES",
				"data": res.data
			})
		});
	}
}


export function getCampaign(campaign_id) {
	return async dispatch => {
		dispatch({
			type: 'LOADING_GET_CAMPAIGN',
		});

		return api.get('campaign/' + campaign_id).then(res => {
			dispatch({
				"type": "GET_CAMPAIGN",
				"data": res.data
			})
		});
	}
}

export function deleteCampaign(index, campaign_id) {
	return async dispatch => {
		dispatch({
			"type": 'DELETE_CAMPAIGN',
			"index": index
		});
		return api.delete('campaign/' + campaign_id)
	}
}

export function getCampaignStatus(campaign_id) {
	return async dispatch => {


		return api.get('campaignStatus/' + campaign_id).then(res => {
			dispatch({
				"type": 'STATUS_CAMPAIGN',
				"data": res.data
			});

		});
	}
}

export function getSurveysStatus(campaign_id, start, end) {
	return async dispatch => {

		return api.get('campaign/' + campaign_id + '/surveys?start=' + start + '&end=' + end).then(res => {
			dispatch({
				"type": 'STATUS_SURVEYS',
				"data": res.data
			});

		});
	}
}

export function getCreativesStatus(campaign_id, start, end) {
	return async dispatch => {

		return api.get('campaign/' + campaign_id + '/creatives?start=' + start + '&end=' + end).then(res => {
			dispatch({
				"type": 'STATUS_CREATIVES',
				"data": res.data
			});

		});
	}
}

export function getSiteStatus(campaign_id, start, end) {
	return async dispatch => {

		return api.get('campaign/' + campaign_id + '/sites?start=' + start + '&end=' + end).then(res => {
			dispatch({
				"type": 'STATUS_SITES',
				"data": res.data
			});

		});
	}
}

export function updateReports(start, end) {
	return async dispatch => {
		dispatch({
			"type": 'UPDATE_SURVEYS_CREATIVES',
			"data": {
				start: start,
				end: end
			}
		});
	}
}
export function getCampaignsActives() {
	return api.get('campaign/actives')
}


export function getUserTypesAccount() {
	return dispatch => {
		return api.get('account/').then(response => {
			dispatch({
				type: "RESPONSE_USER_TYPES_ACCOUNT",
				response: response.data
			});

		});
	}
}
export function getPaineis() {
	return dispatch => {
		return api.get('painel/').then(response => {
			dispatch({
				type: "RESPONSE_PAINEIS",
				response: response.data
			});

		});
	}
}
export function getLanguages() {
	return dispatch => {
		return api.get('languages/').then(response => {
			dispatch({
				type: "RESPONSE_LANGUAGES",
				response: response.data
			});

		});
	}
}
