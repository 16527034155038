import React, { Component } from 'react'

export default class Footer extends Component {
	getYear = () => {
		let data = new Date();
		return data.getFullYear()
	}
	render() {
		return (
			<div className="alert alert-dark mt-5 mb-0">
				<div className="container">
					<p>Brandful {this.getYear()} - All Rights Reserved</p>
				</div>
			</div>
		)
	}
}
