import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { getUsers, deleteUser, saveUser } from '../../actions/userActions'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import api from '../../api';
import { translate } from '../../components/Translate';

export class Administradores extends Component {

	constructor(props) {
		super(props);
		if (!localStorage.getItem('bfToken')) {
			window.location.href = '/';
		}
	}

	async componentDidMount() {
		await this.props.getUsers();
		this.setState({ "update": true });
		gtag('event', 'page_view', {
			'page_title': 'Gerenciamento de Usuários',
			'page_location': window.location.href
		});
	}

	delete = async (i, user_id) => {
		if (window.confirm(translate("Você realmente deseja deletar esse usuário?"))) {
			await this.props.deleteUser(i, user_id);
			this.setState({ "update": true })
		}
	}

	editButton(cell, item) {
		return <Link to={"/administradores/edit/" + item.user_id} className="btn btn-sm btn-success btn-round">{translate('Editar')}</Link>
	}

	recuperarSenhaButton(cell, item) {

		return <button className="btn btn-sm btn-success btn-round" onClick={async () => {
			if (window.confirm(translate("Você realmente deseja enviar a Recuperação senha desse usuário?"))) {
				await api.post("lembrarSenha", { "email": item.email });
				alert(translate("Email enviado com sucesso!"));
			}
		}}> {translate('Recuperar Senha')}</button>
	}

	deleteButton(cell, item) {
		return <button className="btn btn-sm btn-danger btn-round" onClick={() => this.delete(cell, item.user_id)}>{translate('Excluir')}</button>
	}
	goTo(item) {
		window.location.href = '/administradores/edit/' + item.user_id;
	}



	status(cell, item) {
		const toggleUserStatus = item => {
			item.status = (item.status === "0" ? 1 : 0)

			api.patch("users/" + item.user_id, item);
		}

		return (<div className="togglebutton">
			<label>
				<input type="checkbox" defaultChecked={(cell == 1 ? true : false)} onChange={() => toggleUserStatus(item)} />
				<span className="toggle"></span>
			</label>
		</div>)
	}

	handleSizePerPageChange(sizePerPage) {
		localStorage.setItem('perPage', sizePerPage);
	}

	render() {
		const { users } = this.props;
		let perPage = 10;
		if (localStorage.getItem('perPage')) {
			perPage = parseInt(localStorage.getItem('perPage'));
		}
		return (
			<div>
				<Header />
				<div className="alert alert-dark m-0 p-0">
					<div className="container p-0">
						<ol className="breadcrumb">
							<li className="breadcrumb-item active"><a href="#">{translate('Usuários')}</a></li>
						</ol>
					</div>
				</div>

				<div className="container">
					<h3 className="pt-4"><strong>{translate('Gerenciamento de Usuários')}</strong></h3>

					<Link to="/administradores/novo" className="btn btn-success btn-round pull-left" style={{ marginTop: "3px" }}><i className="fa fa-plus"></i> {translate('Novo Usuário')}</Link>
					<a href="https://ws.brandful.com.br/download/users" className="btn  btn-round pull-left" style={{ marginTop: "3px" }}><i className="fa fa-download"></i> {translate('Download Lista de usuários')}</a>
					<div className="pt-5">
						<BootstrapTable data={users} hover search options={{ noDataText: translate("Nenhum usuário até o momento"), sizePerPage: perPage, onSizePerPageList: this.handleSizePerPageChange }} searchPlaceholder={translate("Procurar...")} pagination>
							<TableHeaderColumn isKey dataField='user_id' width='50' dataAlign='center' dataSort={true}>ID</TableHeaderColumn>
							<TableHeaderColumn dataField='name'>{translate('Nome')}</TableHeaderColumn>
							<TableHeaderColumn dataField='lastname'>{translate('Sobrenome')}</TableHeaderColumn>
							<TableHeaderColumn dataField='email'>{translate('Email')}</TableHeaderColumn>
							<TableHeaderColumn dataField='phone'>{translate('Telefone')}</TableHeaderColumn>
							<TableHeaderColumn dataField='group' width='100' dataAlign='center'>{translate('Tipo')}</TableHeaderColumn>
							<TableHeaderColumn dataField='status' width='90' dataAlign='center' dataFormat={this.status}>{translate('Status')}</TableHeaderColumn>
							<TableHeaderColumn dataField='ID' width='100' dataFormat={this.editButton}></TableHeaderColumn>
							<TableHeaderColumn dataField='ID' width='150' dataFormat={this.recuperarSenhaButton}></TableHeaderColumn>
						</BootstrapTable>
					</div>
				</div>
			</div >
		)
	}
}


const mapStateToProps = store => ({
	users: store.user.list,
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ getUsers, deleteUser, saveUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Administradores)
