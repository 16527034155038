import campaign_model from '../models/campaign'
const initialState = {
	list: null,
	loading_save: false,
	loading_get_campaign: true,
	campaign_types_view: [],
	campaign_types: [],
	survey_types: [],
	industries: [],
	account: [],
	paineis: [],
	languages: [],
	current: campaign_model,
	campaign_status: null,
	surveys_status: null,
	creatives_status: null,
	sites_status: null,
	filter_start: null,
	filter_end: null
}

export function campaign(state = initialState, action) {
	let list = state.list;
	switch (action.type) {
		case "RESET_CURRENT":
			return Object.assign({}, state, { current: campaign_model });
		case "LOADING_SAVE":
			return Object.assign({}, state, { loading_save: action.loading_save });
		case "CAMPAIGN_CURRENT_DATA":
			return Object.assign({}, state, { current: action.campaignData });
		case "GET_ALL_CAMPAIGNS":
			return Object.assign({}, state, { list: action.data });
		case "GET_CAMPAIGN":
			return Object.assign({}, state, { current: action.data, loading_get_campaign: false });
		case "DELETE_CAMPAIGN":
			list.splice(action.index, 1)
			return Object.assign({}, state, { list: list });
		case "ADD_CAMPAIGN":
			list.push(action.data);
			return Object.assign({}, state, { list: list });
		case "GET_CAMPAIGN_TYPES":
			return Object.assign({}, state, { campaign_types: action.data });
		case "GET_CAMPAIGN_TYPES_VIEW":
			return Object.assign({}, state, { campaign_types_view: action.data });
		case "GET_INDUSTRIES":
			return Object.assign({}, state, { industries: action.data });
		case "GET_SURVEY_TYPES":
			return Object.assign({}, state, { survey_types: action.data });
		case "LOADING_GET_CAMPAIGN":
			return Object.assign({}, state, { current: campaign_model, loading_get_campaign: true, campaign_status: null, surveys_status: null, getSurveysStatus: false, creatives_status: null });
		case "STATUS_CAMPAIGN":
			return Object.assign({}, state, { campaign_status: action.data });
		case "LOADING_STATUS_CAMPAIGN":
			return Object.assign({}, state, { getSurveysStatus: true });
		case "STATUS_SURVEYS":
			return Object.assign({}, state, { surveys_status: action.data, getSurveysStatus: true });
		case "STATUS_CREATIVES":
			return Object.assign({}, state, { creatives_status: action.data });
		case "STATUS_SITES":
			return Object.assign({}, state, { sites_status: action.data });
		case "UPDATE_SURVEYS_CREATIVES":
			return Object.assign({}, state, { creatives_status: null, surveys_status: null, filter_start: action.data.start, filter_end: action.data.end });
		case "ADD_USER":
			return state.push(action.data);
		case "RESPONSE_USER_TYPES_ACCOUNT":
			return Object.assign({}, state, { list_loading: false, account: action.response });
		case "RESPONSE_PAINEIS":
			return Object.assign({}, state, { paineis: action.response });
		case "RESPONSE_LANGUAGES":
			return Object.assign({}, state, { languages: action.response });
		default:
			return state;
	}
}
