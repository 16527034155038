import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import api from "../../api";
import { getCampaigns } from "../../actions/campaignActions";
import { singleUsers } from "../../actions/userActions";
import { bindActionCreators } from "redux";
//import styled from "styled-components";
import DataTable from "react-data-table-component";
import { translate } from "../Translate";

function shadeColor(color, percent) {

	var R = parseInt(color.substring(1, 3), 16);
	var G = parseInt(color.substring(3, 5), 16);
	var B = parseInt(color.substring(5, 7), 16);

	R = parseInt(R * (100 + percent) / 100);
	G = parseInt(G * (100 + percent) / 100);
	B = parseInt(B * (100 + percent) / 100);

	R = (R < 255) ? R : 255;
	G = (G < 255) ? G : 255;
	B = (B < 255) ? B : 255;

	R = Math.round(R)
	G = Math.round(G)
	B = Math.round(B)

	var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
	var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
	var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

	return "#" + RR + GG + BB;
}

export class Header extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		let { singleUser } = this.props;
		//console.log(singleUser)
		let colorButton = this.singleUser = singleUser.colorButton;
		let colorTextButton = this.singleUser = singleUser.colorTextButton;
		let colorFeatured = this.singleUser = singleUser.colorFeatured;

		this.state = {
			navClass: "navbar fixed-top navbar-expand-lg",
			logoNav: false,
			menuItens: [],
			menuState: {},
			search_visible: "none",
			inlineCss: ''
			// inlineCss:'<style>.btn.btn-success{background:"+colorButton+",color:"+colorTextButton+"}.campaignTitle,.campaignTitle a{{background:"+{colorFeatured}+"}</style>'	
			// inlineCss: '<style>.btn.btn-success{color:#FF4500;}</style>'
			// inlineCss :'<style>.btn.btn-success{background:#00FF00;color:#FF4500;}.campaignTitle,.campaignTitle a{color:#FF00FF;}</style>'

		};



		let css = "<style>.btn.btn-success, .btn-sm{background:" + colorButton + " !important;color:" + colorTextButton + " !important;}";
		css += ".campaignTitle,.campaignTitle a{color:" + colorFeatured + ";}";
		css += ".text-success-dark,.text-success {color:" + colorFeatured + ";	}";

		css += ".form-control, .is-focused .form-control { background-image:" + (!colorFeatured ? "" : "linear-gradient(0deg," + colorFeatured + " 2px,rgba(156,39,176,0) 0)") + " !important; }";

		css += ".btn.btn-success.focus, .btn.btn-success:focus, .btn.btn-success, .btn.btn-secondary:hover{background: " + (!colorButton ? "" : shadeColor(colorButton, -30)) + " !important; }";
		css += ".dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover, .dropdown-menu a:active, .dropdown-menu a:focus, .dropdown-menu a:hover { background-color: " + (!colorButton ? "" : colorButton) + "!important;}"

		// css += ".dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover, .dropdown-menu a:active, .dropdown-menu a:focus, .dropdown-menu a:hover { background-color: "+ colorButton+"!important;}"

		css += ".nav-pills .nav-item .nav-link.active { color: " + (!colorTextButton ? "" : colorTextButton) + ";background-color: " + (!colorButton ? "" : colorButton) + ";}";
		css += ".navbar .navbar-nav .nav-item .nav-link:hover { color: " + (!colorFeatured ? "" : colorFeatured) + ";}";
		css += ".pagination > .page-item.active > a, .pagination > .page-item.active > a:focus, .pagination > .page-item.active > a:hover, .pagination > .page-item.active > span, .pagination > .page-item.active > span:focus, .pagination > .page-item.active > span:hover {	background-color: " + colorButton + ";border-color: " + colorButton + "!important;}}";
		css += '</style>';

		this.state.inlineCss = css
		//  console.log(this.state.inlineCss)


		this.columns = [
			{
				name: "ID",
				selector: "ID",
				wrap: true,
				compact: true,
				width: "50px",
			},
			{
				name: "Pesquisa",
				selector: "name",
				sortable: true,
				wrap: true,
				width: "50%",
			},
			{
				name: "Início",
				selector: "start",
				wrap: true,
			},
			{
				name: "Fim",
				selector: "end",
				wrap: true,
			},
		];

	}

	async componentDidMount() {
		this._isMounted = true;
		window.addEventListener("scroll", this.handleScroll);
		var response = await api.get("menu");
		var menuState = {};
		for (var i = 0; i <= response.data.length; i++) {
			menuState[i] = "";
		}
		this.setState({ menuSurltate: menuState, menuItens: response.data });
		await this.props.getCampaigns();
		await localStorage.getItem('user');

	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	goTo = (item) => {
		window.location.href = "/campanhas/info/" + item.ID;
	};

	_toggleMenu(e, key) {
		e.preventDefault();
		var menuState = this.state.menuState;
		menuState[key] = menuState[key] ? "" : "show";
		// console.log(menuState);
		this.setState({ menuState: menuState });
	}
	_toggleMenuMobile(e) {
		e.preventDefault();

		var elm = document.querySelector(".navbar-toggler");
		var html_elm = document.getElementsByTagName("html")[0];
		var isOpen = html_elm.className;

		if (isOpen) {
			elm.className = elm.className.replace("toggled", "");
			html_elm.className = html_elm.className.replace("nav-open", "");
		} else {
			elm.className = elm.className + " toggled";
			html_elm.className = "nav-open";
		}
	}

	renderMenu = () => {
		return this.state.menuItens.map((value, key) => {
			return value.submenu ? (
				<li
					key={key}
					className={this.state.menuState[key] + " nav-item dropdown"}
					onMouseEnter={(event) => {
						this._toggleMenu(event, key);
					}}
					onMouseLeave={(event) => {
						this._toggleMenu(event, key);
					}}
				>
					<a
						className="nav-link dropdown-toggle"
						target={value.target ? value.target : "_self"}
						href={value.url}
						onClick={(event) => {
							this._toggleMenu(event, key);
						}}

					>{translate(value.label)}</a>
					<div className="ripple-container"></div>
					<div className={this.state.menuState[key] + " dropdown-menu"}>
						{value.submenu.map((subitem, k) => {
							return (
								<a
									key={k}
									href={subitem.url}
									className="dropdown-item"
									target={value.target ? value.target : "_self"}
								>
									{translate(subitem.label)}
								</a>
							);
						})}
					</div>
				</li>
			) : (
				<li className="nav-item" key={key}>
					<a
						className="nav-link"
						target={value.target ? value.target : ""}
						href={value.url}

					>{translate(value.label)}</a>
				</li>
			);
		});
	};

	onChange = (search) => {
		let q = search.target.value.toLowerCase();
		let campaigns_filtered = [];
		if (q === "") {
			this.setState({
				search_visible: "none",
				campaigns_filtered: campaigns_filtered,
			});
		} else {
			this.campaigns.map((item) => {
				if (item.name.toLowerCase().indexOf(q) > -1) {
					campaigns_filtered.push(item);
				}
			});
			this.setState({
				search_visible: "block",
				campaigns_filtered: campaigns_filtered,
			});
		}
	};

	render() {
		const { campaigns, singleUser } = this.props;
		this.campaigns = campaigns;
		this.singleUser = singleUser;
		const { inlineCss } = this.state;

		// console.log(inlineCss)

		return (

			<div>
				<div dangerouslySetInnerHTML={{ __html: inlineCss }}></div>
				<nav className={this.state.navClass} id="sectionsNav">
					<div className="container">
						<div className="navbar-translate">
							{this.state.logoNav ? (
								<a className="navbar-brand" href="/">
									<img src="singleUser" height="55" alt="Brandful" />
								</a>
							) : (
								<a className="navbar-brand" href="/">
									{this.singleUser.logo === '' || this.singleUser.logo === null ?
										<img src="/assets/img/logo.png" height="55" alt="Brandful" /> :
										<img src={this.singleUser.logo} height="55" alt={this.singleUser.account} />
									}


								</a>
							)}
							<button
								className="navbar-toggler"
								type="button"
								onClick={(e) => {
									this._toggleMenuMobile(e);
								}}
							>
								<span className="sr-only">Toggle navigation</span>
								<span className="navbar-toggler-icon"></span>
								<span className="navbar-toggler-icon"></span>
								<span className="navbar-toggler-icon"></span>
							</button>
						</div>
						<form className="form-inline hidden-print">
							<div className="form-group bmd-form-group">
								<input
									type="text"
									className="form-control"
									placeholder={translate("Procurar Pesquisa")}
									onChange={(val) => {
										this.onChange(val);
									}}
								/>
							</div>
							<button
								type="submit"
								className="btn btn-white btn-raised btn-fab btn-round"
							>
								<i className="material-icons">search</i>
							</button>
							{campaigns === null ? null : (
								<DataTable
									columns={this.columns}
									data={this.state.campaigns_filtered}
									noHeader
									className="suggest"
									fixedHeader
									fixedHeaderScrollHeight="50vh"
									dense
									onRowClicked={this.goTo}
									style={{ display: this.state.search_visible }}
								/>
							)}
						</form>
						<div className="collapse navbar-collapse hidden-print">
							<ul className="navbar-nav ml-auto">{this.renderMenu()}</ul>
						</div>
					</div>
				</nav>
			</div>
		);
	}
}

const mapStateToProps = (store) => ({
	campaigns: store.campaign.list,
	singleUser: JSON.parse(localStorage.getItem('user')),
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ getCampaigns }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
