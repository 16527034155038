import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { getReportAnswers } from '../../../../../actions/aiActions';
import AnimateHeight from 'react-animate-height';
import { translate } from '../../../../../components/Translate';

function shadeColor(color, percent) {

	var R = parseInt(color.substring(1, 3), 16);
	var G = parseInt(color.substring(3, 5), 16);
	var B = parseInt(color.substring(5, 7), 16);

	R = parseInt(R * (100 + percent) / 100);
	G = parseInt(G * (100 + percent) / 100);
	B = parseInt(B * (100 + percent) / 100);

	R = (R < 255) ? R : 255;
	G = (G < 255) ? G : 255;
	B = (B < 255) ? B : 255;

	R = Math.round(R)
	G = Math.round(G)
	B = Math.round(B)

	var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
	var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
	var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

	return "#" + RR + GG + BB;
}


export class Answers extends Component {

	opt_chart = {
		tooltips: false,
		maintainAspectRatio: false,
		scales: {
			yAxes: [{
				display: true,
				ticks: {
					callback: function (label) {
						return label + '%'
					},
					beginAtZero: true,
					min: 0,
					fontColor: '#000'
				},
			}],
			xAxes: [{
				ticks: {
					fontSize: 14,
					fontFamily: 'Roboto',
					fontColor: '#000'
				}
			}]
		},
		animation: {
			duration: 500,
			onProgress: this.animationChart,
			onComplete: this.animationChart
		},
		legend: {
			display: true,
			labels: {
				fontColor: '#222',
				fontFamily: 'Roboto',
				fontSize: 11,
			},
			onClick: function () {
				return false;
			}
		}
	}

	state = {
		exibir_ai: false,
		ai_content: '',
	}

	animationChart() {
		var chartInstance = this.chart,
			ctx = chartInstance.ctx;
		ctx.font = "14px Roboto";
		ctx.fillStyle = "#fff";
		ctx.textAlign = 'center';
		ctx.textBaseline = 'bottom';

		this.data.datasets.forEach(function (dataset, i) {
			var meta = chartInstance.controller.getDatasetMeta(i);
			meta.data.forEach(function (bar, index) {
				var data = dataset.data[index] + '%';
				if (bar._model.y > 280) {
					ctx.fillStyle = "#333";
					ctx.fillText(data, bar._model.x, bar._model.y);
					ctx.fillStyle = "#fff";
				} else {
					ctx.fillText(data, bar._model.x, bar._model.y + 30);
				}
			});
		});
		document.getElementById("chart_answers").src = chartInstance.toBase64Image();
	}

	getDataControle = alternatives => {
		let data = [];
		const { survey } = this.props;
		Object.values(alternatives).map(item => {
			let controle = ((item.controle / survey.controle.total) * 100);
			data.push(controle.toFixed(1));
		})
		return data;
	}

	getDataExposto = alternatives => {
		let data = [];
		const { survey } = this.props;
		Object.values(alternatives).map(item => {
			let exposto = ((item.exposto / survey.exposto.total) * 100);
			data.push(exposto.toFixed(1));
		})
		return data;
	}

	result(item) {
		const { survey } = this.props;
		if (survey.exposto.total == 0 || survey.controle.total == 0) {
			return '-';
		} else {
			let exposto = (item.exposto / survey.exposto.total) * 100;
			let controle = (item.controle / survey.controle.total) * 100;

			let result = (exposto - controle).toFixed(1);
			return result;
		}
	}

	render() {
		const { survey, singleUser } = this.props;

		const chartData = {
			labels: Object.keys(survey.alternatives),
			datasets: [{
				label: translate('Controle'),
				data: this.getDataControle(survey.alternatives),
				// backgroundColor:'#a0b785'
				backgroundColor: !singleUser['colorSecondary'] ? '#83ecbf' : singleUser['colorSecondary'],
			}, {
				label: translate('Exposto'),
				data: this.getDataExposto(survey.alternatives),
				backgroundColor: !singleUser['colorFeatured'] ? '#26d988' : singleUser['colorFeatured'],
				// backgroundColor:'#67aa1a'
			}]
		};

		return (
			<div>
				<h3 className="pl-4 pt-4 pb-2"><strong>{translate('Respostas')}</strong></h3>
				<div className="container-fluid pb-4">
					<div className="col-md-10 offset-md-1 d-none d-print-block">
						<img src="" id="chart_answers" className="img-responsive" />
					</div>
					<div className="col-md-10 offset-md-1 d-print-none">
						<Bar data={chartData} options={this.opt_chart} height={350} />
					</div>
				</div>
				<div className="border-vertical">
					<table className="table table-bordered">
						<thead className="text-center">
							<tr>
								<th rowSpan="2" className="align-text-bottom">{translate('Respostas')}</th>
								<th colSpan="2" className="l-border">{translate('Controle')}</th>
								<th colSpan="2" className="l-border">{translate('Exposto')}</th>
								<th rowSpan="2" className="align-text-bottom l-border">{translate('Diff')}</th>
							</tr>
							<tr className="small font-weight-bold">
								<th>Votos</th>
								<th>Totais</th>
								<th className="l-border">Votos</th>
								<th>Totais</th>
							</tr>
						</thead>
						<tbody>
							{
								Object.keys(survey.alternatives).map((item, key) =>
									<tr key={key}>
										<td className="pl-4">{item}</td>
										<td className="text-center">{survey.alternatives[item].controle}</td>
										<td className="text-center">{survey.controle.total}</td>
										<td className="text-center">{survey.alternatives[item].exposto}</td>
										<td className="text-center">{survey.exposto.total}</td>
										<td className="text-center">{this.result(survey.alternatives[item])}</td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
				<div className='p-3'>
					<h6 className='text-success'><a href="" onClick={async (e) => {
						e.preventDefault();

						this.setState({
							exibir_ai: !this.state.exibir_ai,
							refresh_ai: false,
							ai_content: "<p class='text-center'><i class='fa fa-spin fa-refresh'></i></p>"
						});
						if (!this.state.exibir_ai) {
							survey.refresh = false;
							let response = await getReportAnswers(survey);
							this.setState({
								ai_content: response.data.text,
								refresh_ai: true
							});
						}
					}
					} className='d-flex align-items-center gap-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lightning-charge" viewBox="0 0 16 16">
							<path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41z" />
						</svg> {translate('AI Analysis - Answers')} (BETA) <i className={this.state.exibir_ai ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i></a></h6>
					<AnimateHeight duration={1000} height={(this.state.exibir_ai ? 'auto' : 0)} style={{ fontSize: '0.6rem' }}>
						<div dangerouslySetInnerHTML={{ __html: this.state.ai_content }}></div>
						{this.state.refresh_ai ? <button className="btn btn-round btn-success btn-sm hidden-print" onClick={async (e) => {
							e.preventDefault();

							this.setState({
								ai_content: "<p class='text-center'><i class='fa fa-spin fa-refresh'></i></p>",
								refresh_ai: false
							});
							let postData = survey;
							postData.refresh = true;
							let response = await getReportAnswers(postData);
							this.setState({
								ai_content: response.data.text,
								refresh_ai: true
							});
						}
						}><i className='fa fa-magic'></i> {translate('Gerar Nova Análise')}</button> : null}
					</AnimateHeight>
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => ({
	singleUser: JSON.parse(localStorage.getItem('user'))
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Answers)
