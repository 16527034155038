import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getSurveysStatus } from '../../../../../actions/campaignActions'
import palette from 'google-palette';
import SurveyDetail from './surveyDetail'

export class Surveys extends Component {

	constructor(props) {
		super(props);
		this.props.getSurveysStatus(this.props.infoData.campaign_id, this.props.start, this.props.end);
	}

	componentDidUpdate() {
		if (this.props.surveys_status === null) {
			this.props.getSurveysStatus(this.props.infoData.campaign_id, this.props.filter_start, this.props.filter_end);
		}
	}



	render() {
		const { loading_get_campaign, infoData, start, end, surveys_status } = this.props;

		return (
			<div>
				{surveys_status !== null && infoData !== null ?
					<div>
						{surveys_status.map((item, key) => <SurveyDetail key={key} index={key} survey={item} campaign={infoData} />)}
					</div>
					: <p className="text-center p-4"><span className="fa fa-refresh fa-spin fa-4x"></span></p>
				}
			</div>
		)
	}
}

const mapStateToProps = store => ({
	infoData: store.campaign.current,
	surveys_status: store.campaign.surveys_status,
	loading_get_campaign: store.campaign.loading_get_campaign,
	getSurveysStatus: getSurveysStatus,
	filter_start: store.campaign.filter_start,
	filter_end: store.campaign.filter_end,
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ getSurveysStatus }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Surveys)
