export function translate(text) {
	let lang = window.navigator.language.toLowerCase();
	if (lang.indexOf('es') > -1) {
		lang = 'es';
	} else if (lang.indexOf('en') > -1) {
		lang = 'en-us';
	} else {
		lang = 'pt-br';
	}
	let user = localStorage.getItem("user");
	if (user) {
		user = JSON.parse(user);
		lang = user.language;
	}

	const translate = require("./i18n/" + lang + ".json");
	if (translate[text]) {
		return translate[text];
	} else {
		return text;
	}
}
