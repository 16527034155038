import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import Status from '../status'
import DatePicker, { setDefaultLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import "./info.css"
import ptBR from 'date-fns/locale/pt-BR'
import Surveys from './components/surveys'
import {updateReports} from '../../../../actions/campaignActions'


setDefaultLocale('ptBR', ptBR)
 
 
export class Estatisticas extends Component {

    constructor(props){
        super(props)
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            minDateEnd: new Date(),
            campaign_id: props.infoData.campaign_id,
        };
        this.state.minDate = new Date(Date.parse(props.infoData.start));
        this.state.startDate = this.state.minDate;
        this.state.minDateEnd = this.state.minDate;
        const endDate =  new Date(Date.parse(props.infoData.end))
        this.state.maxDate = new Date();
        if(endDate.getTime() < this.state.maxDate.getTime()){
            this.state.maxDate = endDate;
            this.state.endDate = endDate;
        }
        this.filtrar = this.filtrar.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }
    
    handleChange = date => {
        this.setState({
          startDate: date,
          minDateEnd: date
        });
    };
    handleChangeEnd = date => {
        this.setState({
            endDate: date,
        });
    };
    dateFormat = date => {
        let month = date.getMonth()+1;
        let day = date.getDate();
        if(month < 10){
            month = '0'+month;
        }
        if(day < 10){
            day = '0'+day;
        }

        return date.getFullYear()+'-'+month+'-'+day;
    }

    filtrar () {
        this.props.updateReports(this.dateFormat(this.state.startDate), this.dateFormat(this.state.endDate));
        this.setState({update: true})
    }

    render() {
        return (
            <div>
                <Surveys start={this.dateFormat(this.state.startDate)} end={this.dateFormat(this.state.endDate)} />
            </div>
        )
    }
}

const mapStateToProps = store => ({
    infoData: store.campaign.current,
    loading_get_campaign: store.campaign.loading_get_campaign

    
})

const mapDispatchToProps = dispatch => 
    bindActionCreators({ updateReports }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Estatisticas)
