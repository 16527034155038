import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import { getCampaign } from '../../../actions/campaignActions'
import { bindActionCreators } from 'redux'
import Implementacao from './implementacao'
import Estatisticas from './Estatisticas'
import Status from './status'
import { translate } from '../../../components/Translate'

export class InfoCampanha extends Component {
	constructor(props) {
		super(props);
		if (!localStorage.getItem('bfToken')) {
			window.location.href = '/';
		}
		this.state = {
			currentTab: 'estatisticas'
		}
	}

	async componentDidMount() {
		let campaign_id = this.props.match.params.id

		await this.props.getCampaign(campaign_id);
		this.setState({ "update": true });
		gtag('event', 'page_view', {
			'page_title': 'Informações Pesquisa ' + this.props.infoData.name,
			'page_location': window.location.href
		});
	}

	activeTab = (event, href) => {
		event.preventDefault();
		this.setState({ currentTab: href });
	}

	showCurrentTab = () => {
		switch (this.state.currentTab) {
			case "estatisticas":
				return <Estatisticas />
				break;
			default:
				return <Implementacao />
				break;
		}
	}

	formata_data = data => {
		return data.split('-').reverse().join('/');
	}

	render() {
		const { infoData, loading_get_campaign } = this.props;
		const { currentTab } = this.state;

		return (
			<div>
				<Header />

				<div className="alert alert-dark m-0 p-0">
					<div className="container p-0">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="/campanhas/">{translate('Pesquisas')}</a></li>
							<li className="breadcrumb-item active">{translate('Detalhes')}</li>
						</ol>
					</div>
				</div>


				{loading_get_campaign ? null :
					(infoData.name ?
						<div>
							<div className="container pb-3">
								<h2 className="title h3"> {infoData.advertiser} - {infoData.name} ({translate(infoData.industry ? infoData.industry : 'Outros')})</h2>
							</div>
							<Status />
							<div className="container">
								<div className="row hidden-print">
									<div className="col-md-5">
										<ul className="nav nav-pills nav-fill">
											<li className="nav-item"><a className={(currentTab === 'estatisticas' ? "nav-link active" : "nav-link")} href="estatisticas" onClick={(e) => { this.activeTab(e, 'estatisticas') }}>{translate('RESULTADOS')}</a></li>
											<li className="nav-item"><a className={(currentTab === 'implementacao' ? "nav-link active" : "nav-link")} href="implementacao" onClick={(e) => { this.activeTab(e, 'implementacao') }} >{translate('IMPLEMENTAÇÃO')}</a></li>
										</ul>
									</div>
								</div>

								{loading_get_campaign ? null : this.showCurrentTab()}

							</div>
						</div>
						: <Redirect to="/" />)
				}

				<Footer />

			</div>
		)
	}
}

const mapStateToProps = store => ({
	infoData: store.campaign.current,
	loading_get_campaign: store.campaign.loading_get_campaign
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ getCampaign }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(InfoCampanha)
