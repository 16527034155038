import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeCampaignData, getCampaigns } from '../../../actions/campaignActions'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import { listCampaigns } from '../../../actions/userActions';
import { addCampaigns } from '../../../actions/userActions';
import { User } from '../../../actions/userActions';

import { getCampaignsId } from '../../../actions/userActions';
import { deleteCampaignsId } from '../../../actions/userActions';
import { translate } from '../../../components/Translate';

export class Campaign extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			campaignsLoaded: false,
			users: [],
			"campaigns": [],
			"campaign": [],
			"teste": []
		}

		this.onChange = this.onChange.bind(this);
		this.addCampaignsId = this.addCampaignsId.bind(this)

		this.listSearch()
		this.filterCampaigns()


	}

	async listSearch() {
		let { idContact } = this.props;

		let result = await getCampaignsId(idContact)
		this.setState({
			"campaign": result.data
		})
		this.setState({ update: true });

	}

	async wait(value) {
		await deleteCampaignsId(value)

	}

	async filterCampaigns() {

		if (this.props.userData.email != '') {

			let result = await listCampaigns(this.props.userData.email)

			this.setState({
				"campaigns": result.data,
				"campaignsLoaded": true

			})
		}

	}


	addCampaignsId(userdata) {

		let campaigns = this.state.campaigns;

		campaigns.map((campaign, key) => {
			if (campaign.ID === userdata.ID) {//antes == depois ===                
				campaigns.splice(key, 1);
			}
		}
		)
		this.state.campaign.push(
			{
				"campaign_id": userdata.ID,
				"advertiser": userdata.advertiser,
				"name": userdata.name
			});
		// this.state.userdata.splice();
		let { idContact } = this.props;
		addCampaigns(idContact, userdata.ID)
		this.setState({ update: true });

		//    this.props.onSelect()        
	}



	onChange(e, index) {
		let data = this.props.campaignData

		data.contacts[index][e.target.name] = e.target.value;

		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	_novoContato = () => {
		if (this.props.userData.email == '') {
			alert("Gentileza preecher campo Email");
		} else {
			this.setState({ showModal: true });
		}

	}
	closeModal = () => {
		this.setState({ showModal: false });
	}

	addButton(cell, item, extra) {

		return <button className="btn btn-sm btn-success btn-round" type="button" onClick={() => { extra(item) }}>Adicionar</button>

	}

	_removeContato = index => {
		let data = this.state.campaign[index]

		this.wait(data["campaign_contact_id"]);
		// this.props.onSelect(this.setState({ update: true }))
		this.state.campaign.splice(index, 1);
		this.state.campaigns.push(
			{
				"ID": data.campaign_id,
				"advertiser": data.advertiser,
				"name": data.name
			});
		this.setState({ update: true });
	}


	render() {
		const { userData } = this.props;

		const { campaignsLoaded, showModal, campaigns, campaign } = this.state;

		if (!campaignsLoaded) {
			this.filterCampaigns();
		}

		const email = userData.email;


		return (
			<fieldset className="mt-2">
				<h4 className="card-title">{translate('Adicionar Pesquisa')}</h4>

				<button type="button" className="btn btn-success btn-round" onClick={this._novoContato}><i className="fa fa-plus"></i> {translate('Adicionar')}</button>


				{showModal ?
					<div>

						<div className="modal fade show" style={{ display: "block" }}>
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">{translate('Adicionar contato para essa pesquisa')}</h5>

										<button type="button" className="close" onClick={() => this.closeModal()}>
											<i className="material-icons">clear</i>
										</button>
									</div>
									<div className="modal-body">
										<BootstrapTable data={campaigns} hover search options={{ noDataText: translate("Nenhuma pesquisa encontrado"), sizePerPageDropDown: '' }} searchPlaceholder={translate("Procurar...")} pagination>
											<TableHeaderColumn isKey dataField='ID' width='50' dataAlign='center' dataSort={true}>ID</TableHeaderColumn>
											<TableHeaderColumn dataField='name'>{translate('Anunciante')}</TableHeaderColumn>
											<TableHeaderColumn dataField='account'>{translate('Titulo')}</TableHeaderColumn>
											<TableHeaderColumn formatExtraData={this.addCampaignsId} dataField='ID' width='100' dataAlign='center' dataFormat={this.addButton}></TableHeaderColumn>
										</BootstrapTable>
									</div>

								</div>
							</div>
						</div>

						<div className="modal-backdrop fade show" onClick={() => this.closeModal()}></div>
					</div>
					: null
				}

				<table className="table table-striped">
					<thead>
						<tr>
							<th>ID</th>
							<th>{translate('Pesquisa')}</th>
							<th>{translate('Anunciante')}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>

					</tbody>
					<tbody>
						{
							campaign.map((item, i) => {

								return (
									<tr key={i}>

										<td>{item.campaign_id}</td>
										<td>{item.advertiser}</td>
										<td>{item.name}</td>
										<td><button type="button" className="btn btn-danger btn-sm btn-circle" onClick={() => this._removeContato(i)}><i className="fa fa-minus"></i></button></td>
										{/* {(botao = 0) => {}{}}} */}
									</tr>
								)
							})
						}
					</tbody>
				</table>

			</fieldset >

		)
	}
}
const mapStateToProps = store => ({

	campaignData: store.campaign.current,
	changeCampaignData: changeCampaignData,
	userData: store.user.edit_user,
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ changeCampaignData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Campaign)
