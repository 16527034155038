import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeCampaignData } from '../../../actions/campaignActions'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { getUsersAtivos } from '../../../actions/userActions';
import { translate } from '../../../components/Translate'

export class Contacts extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			users: [],
			botaoOn: false
		}
		this.onChange = this.onChange.bind(this);
		this.addUser = this.addUser.bind(this)

	}


	filterContacts() {
		let users = [];

		let campaignData = this.props.campaignData
		let contatcs = [];
		campaignData.contacts.map(contact => {
			contatcs.push(contact.email);
		})
		getUsersAtivos().then(response => {
			response.data.map(data => {

				if (contatcs.indexOf(data.email) === -1) {
					users.push(data);
				}
			})
			this.setState({ users })
		})
	}

	addUser(userdata) {


		this.setState({ botaoOn: true });
		let data = this.props.campaignData;
		let users = this.state.users;
		// console.log(data.contacts)
		// console.log(userdata.user_id)



		if (data.contacts.indexOf(userdata) === -1) {
			//    alert("aquki")

			users.map((user, key) => {
				if (user.user_id === userdata.user_id) {//antes == depois ===
					users.splice(key, 1);
				}
			})
			this._removeCampagn();
			data.contacts.push(userdata);
			this.props.changeCampaignData(data);
			this.setState({ update: true });

		}

		this.setState({ botaoOn: false });
	}


	async onChange(e, index) {
		let data = await this.props.campaignData
		data.contacts[index][e.target.name] = e.target.value;

		this.props.changeCampaignData(data);

		console.log("Caiu aqui")
		this.setState({ update: true });
	}

	_novoContato = () => {
		this.setState({ showModal: true });
	}
	closeModal = () => {
		this.setState({ showModal: false });
	}

	addButton(cell, item, extra) {

		if (!this.botaoOn) {
			return <button className="btn btn-sm btn-success btn-round" type="button" onClick={() => { extra(item) }}>{translate('Adicionar')}</button>

		}
	}

	_removeContato = index => {
		let data = this.props.campaignData
		this.state.users.push(data.contacts[index])
		data.contacts.splice(index, 1);
		this.props.changeCampaignData(data);
		this.setState({ update: true });

	}

	_removeCampagn = index => {
		let data = this.props.campaignData

		data.account_id = '';
		this.props.changeCampaignData(data);
		this.setState({ update: true });
		this.props.updateMyState()
	}




	render() {
		let { campaignData, deletedUser } = this.props;
		let { users, showModal } = this.state;

		//     if(deletedUser != users ){
		//         //users = deletedUser
		//          deletedUser.forEach(element =>

		//            users.push(element) 

		//          );
		//   }

		let data = this.props.campaignData
		if (users.length == 0) {
			this.filterContacts()
		}


		return (
			<fieldset className="mt-2">
				{/* <h4 className="card-title">Por contato:</h4> */}
				<label>{translate('Por contato')}:</label><br></br>
				{campaignData.contacts.length > 0 ?
					<table className="table">
						<thead>
							<tr>
								<th>{translate('Nome')}</th>
								<th>{translate('Sobrenome')}</th>
								<th>{translate('Email')}</th>
								<th>{translate('Telefone')}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{campaignData.contacts.map((item, i) => {
								return (
									<tr key={i}>
										<td>{item.name}</td>
										<td>{item.lastname}</td>
										<td>{item.email}</td>
										<td>{item.phone}</td>
										<td><button type="button" className="btn btn-danger btn-sm btn-circle" onClick={() => this._removeContato(i)}><i className="fa fa-minus"></i></button></td>
									</tr>
								)
							})}
						</tbody>
					</table>
					: null}
				<button type="button" className="btn btn-success btn-round" onClick={this._novoContato}><i className="fa fa-plus"></i> {translate('Adicionar')}</button>
				{showModal ?
					<div>
						<div className="modal fade show" style={{ display: "block" }}>
							<div className="modal-dialog modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">{translate('Adicionar contato para essa pesquisa')}</h5>
										<button type="button" className="close" onClick={() => this.closeModal()}>
											<i className="material-icons">clear</i>
										</button>
									</div>
									<div className="modal-body">
										<BootstrapTable data={users} hover search options={{ noDataText: translate("Nenhum usuário encontrado") }} searchPlaceholder={translate("Procurar...")} pagination>
											<TableHeaderColumn isKey dataField='user_id' width='50' dataAlign='center' dataSort={true}>ID</TableHeaderColumn>
											<TableHeaderColumn dataField='name'>{translate('Nome')}</TableHeaderColumn>
											<TableHeaderColumn dataField='lastname'>{translate('Sobrenome')}</TableHeaderColumn>
											<TableHeaderColumn dataField='email'>{translate('Email')}</TableHeaderColumn>
											<TableHeaderColumn dataField='phone'>{translate('Telefone')}</TableHeaderColumn>
											<TableHeaderColumn formatExtraData={this.addUser} dataField='ID' width='100' dataAlign='center' dataFormat={this.addButton}></TableHeaderColumn>
										</BootstrapTable>
									</div>
								</div>
							</div>
						</div>

						<div className="modal-backdrop fade show" onClick={() => this.closeModal()}></div>
					</div>
					: null}
			</fieldset>
		)
	}
}
const mapStateToProps = store => ({
	campaignData: store.campaign.current,
	changeCampaignData: changeCampaignData
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ changeCampaignData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
