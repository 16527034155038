import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { loginFace } from "../../actions/userActions";
import { bindActionCreators } from "redux";
import { eventVideo, getVideo } from '../../actions/watchActions';


const videoConstraints = {
	width: 450,
	height: 340,
	facingMode: "user"
};


export default class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			response_login: false,
			infoData: null,
			termos: true,
			redirect_loading: false
		};
		this.changeTermos = this.changeTermos.bind(this);
	}



	async componentDidMount() {
		//document.body.classList.add("login-page");
		if (this.props.campaign_id) {
			this.campaign_id = this.props.campaign_id;
			let response = await getVideo(this.campaign_id);
			this.setState({
				infoData: response.data
			});
			gtag('event', 'page_view', {
				'page_title': 'Painel ' + response.data['name'],
				'page_location': window.location.href
			});
		}
	}

	closeModal() {
		this.setState({ showWebcam: false, redirect_loading: false });
	}
	changeTermos(e) {
		this.setState({
			termos: e.target.checked
		});
	}

	cleanUrl(url) {

		this.setState({
			redirect_loading: true
		});
		let tags = url.match(/({{[a-z_0-9]+}})/gi);
		let query_string = window.location.search.replaceAll("%3C", "").replaceAll("%3E", "").replaceAll('(', '').replaceAll(')', '');
		let params = new URLSearchParams(query_string);

		tags.map((val) => {
			url = url.replace(val, params.get(val.match(/[a-z_0-9]+/)[0]));
		});

		const redirect_url = new URL(url)

		// console.log(redirect_url)
		let searchParams = new URLSearchParams(redirect_url.search);
		let post = { "campaign_id": this.state.infoData.campaign_id, "type_page": "Video" };
		for (var pair of searchParams.entries()) {
			post[pair[0]] = pair[1];

		}


		eventVideo(post).then(() => {
			window.location.href = url;

		});

		// this.props.onLogin();
	}

	render() {
		const { response_login, infoData, termos, redirect_loading } = this.state;
		console.log(infoData)
		let url = "";
		if (infoData != null) {
			url = infoData.redirect_reject;
		}


		return (
			infoData == null ? <div>
				<p className="text-center py-4">
					<span className="fa fa-4x fa-refresh fa-spin"></span>
				</p>
			</div>

				:
				<div>
					<nav className="navbar fixed-top navbar-expand-lg" id="sectionsNav">
						<div className="container-fluid">
							<div className="navbar-translate mb-2 mt-2">
								<img src="/assets/img/logo.png" height="55" alt="Brandful" />
							</div>

							<div className="collapse navbar-collapse hidden-print">
								<ul className="navbar-nav ml-auto">
									<li className="nav-item">
										{/* <a className="nav-link" target="_blank" href="https://emotions-ws.brandful.com.br/privacy">Termos de Uso</a> */}
										<a className="nav-link" target="_blank" href="https://brandful.com.br/privacy_stat.html">Termos de Uso</a>

									</li>
								</ul>
							</div>
						</div>
					</nav>

					<ol className="breadcrumb py-4">
						<li className="breadcrumb-item font-weight-bold">Painelista | Bem Vindo</li>
					</ol>
					<div className="container-fluid" style={{ paddingBottom: '60px' }}>

						<h3 className="pt-4" style={{ overflowWrap: "break-word" }}><strong>{infoData['name']}</strong></h3>

						<p>Assista ao vídeo abaixo para participar da pesquisa.</p>
						<ul className="list-unstyled text-muted font-weight-bold">
							<li>Passo 1: Clique sobre o botão iniciar abaixo para começar a pesquisa.</li>
							<li>Passo 2: Assista o comercial até o fim.</li>
							<li>Passo 3: Aguarde  a mensagem de sucesso.</li>
						</ul>


						<p className="font-weight-bold">Minhas Tarefas</p>
						<div className="card d-md-none">
							<img src={infoData['thumbnail']} className="card-img-top" alt={infoData['name']} />
							<div className="card-body">
								<dl className="row">
									<dt className="col-sm-3">Tempo</dt>
									<dd className="col-sm-9">{infoData['video_duration']}s</dd>
									<dt className="col-sm-3">Qtd. Vídeos</dt>
									<dd className="col-sm-9">1</dd>
								</dl>
								{/* <label htmlFor="termos">
                       <span className="pr-2"><input type="checkbox" id="termos" name="termos" value="1" onChange={this.changeTermos} /></span> 
                      Li e aceiro os <a href="https://emotions-ws.brandful.com.br/privacy" target="_blank">termos de uso e política de privacidade</a> para participação nesta pesquisa.
                  </label> */}
								{redirect_loading ? <div className="text-center"><span className="fa fa-4x fa-refresh fa-spin"></span></div> :
									<div className="text-center">

										<button className={termos ? "btn btn-success btn-round btn-block" : "btn btn-success btn-round disabled btn-block"} onClick={() => this.props.onLogin()}>
											<span className="fa fa-eye"></span> Assistir
										</button>
										{url != null ?
											<p><button onClick={() => { this.cleanUrl(url) }} className="btn btn-round btn-block">Recusar Pontos</button></p>
											: null}
									</div>}
							</div>
						</div>
						<table className="table table-hover table-bordered d-none d-md-table">
							<thead>
								<tr>
									<th colSpan="2">ID</th>
									<th>Tempo</th>
									<th>Qtd. Vídeos</th>
									<th width="100"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{infoData['campaign_id']}</td>
									<td><img src={infoData['thumbnail']} height="150px" /></td>
									<td>{infoData['video_duration']}s</td>
									<td>1</td>
									{/* <td width="150" className="text-center">
                      <label htmlFor="termos">
                        <span className="d-block"><input type="checkbox" id="termos" name="termos" value="1" onChange={this.changeTermos} /></span>
                        Li e aceiro os <a href="https://emotions-ws.brandful.com.br/privacy" target="_blank">termos de uso e política de privacidade</a> para participação nesta pesquisa.
                      </label>
                    </td> */}
									<td>
										{redirect_loading ? <div className="text-center"><span className="fa fa-refresh fa-spin"></span></div> :
											<div className="text-center">
												<button className={termos ? "btn btn-success btn-round btn-block" : "btn btn-success btn-round disabled btn-block"} onClick={() => this.props.onLogin()}>
													<span className="fa fa-eye"></span> Assistir
												</button>
												{url != null ?
													<p><button onClick={() => { this.cleanUrl(url) }} className="btn btn-round btn-block">Recusar Pontos</button></p>
													: null}
											</div>
										}
									</td>
								</tr>
							</tbody>
						</table>
					</div>

				</div>
		);
	}
}
